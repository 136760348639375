import styles from "./Profile.module.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { auth, functions, storage } from "./../../../firebase";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { format } from "date-fns";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { signOut, sendPasswordResetEmail } from "firebase/auth";
import Message from "../../common/Message";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import imageCompression from "browser-image-compression";

const Profile = ({ user, setUser }) => {
  const minAge = 18;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [addDelete, setAddDelete] = useState(false);
  const [deletePicture, setDeletePicture] = useState(false);
  const [isoDate, setIsoDate] = useState("");

  const [modal, setModal] = useState({
    show: false,
    title: "",
    message: "",
  });

  const [profile, setProfile] = useState({
    name: user.name,
    lastName: user.lastName,
    email: user.email,
    birthDate: user.birthDate,
    gender: user.gender,
  });

  useEffect(() => {
    const dateValues = user.birthDate.split(" ");
    const date = new Date();
    date.setDate(dateValues[0]);
    date.setMonth(parseInt(dateValues[1]) - 1);
    date.setFullYear(dateValues[2]);
    const dateString = format(date, "yyyy-MM-dd");
    setIsoDate(dateString);
    setProfile({
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      birthDate: dateString,
      gender: user.gender,
    });
    getImageUrl();
  }, []);

  const prepareDeletePicture = () => {
    handleClose();
    setUrl(null)
    setAddDelete(false);
    setDeletePicture(true);
  };

  const showModalError = (message) => {
    setModal({
      show: true,
      title: "Error",
      message: message,
    });
  };

  const showPassModal = () => {
    setDeletePicture(false);
    setUpdate(true);
    setModal({
      show: true,
      title: t("updatePassword"),
      message: t("updatePasswordDescription", { email: user.email }),
    });
  };

  const showDeleteModal = () => {
    setDeletePicture(false);
    setUpdate(false);
    setModal({
      show: true,
      title: t("deleteAccount"),
      message: t("deleteAccountDescription"),
    });
  };

  const showDeletePictureModal = () => {
    setDeletePicture(true);
    setModal({
      show: true,
      title: t("deletePictureTitle"),
      message: t("deletePictureMessage"),
    });
  };

  const handleClose = () => {
    setDeletePicture(false);
    setModal({
      show: false,
      title: "",
      message: "",
    });
  };

  const handleFile = (event) => {
    const selected = event.target.files[0];
    setFile(selected);
    setUrl(URL.createObjectURL(selected));
    setAddDelete(true);
    setDeletePicture(false);
  };

  const handleSubmit = async (event) => {
    if (loading) return;
    // Prevent page reload
    event.preventDefault();
    const selectedDate = new Date(profile.birthDate);
    const currentDate = new Date();
    const difference = currentDate.getFullYear() - selectedDate.getFullYear();
    if (difference < minAge) {
      alert(t("minAgeErro", { age: minAge }));
      return;
    } else {
      await updateProfileInfo();
    }
  };

  const uploadPicture = async (userId) => {
    // Create path reference
    const path = "user/" + userId + "/profile_photo/profile_photo.jpg";
    const reference = ref(storage, path);
    try {
      await uploadBytes(reference, file);
    } catch (error) {
      // TODO: Handle correctly
    }
  };

  const uploadSmallPicture = async (userId) => {
    // Create path reference
    const path = "user/" + userId + "/small_photo/profile_photo.jpg";
    const reference = ref(storage, path);
    const options = {
      maxSizeMB: 1, // set a max file size in megabytes after compression.
      maxWidthOrHeight: 1920, // set a max dimension (either width or height) for the output image
      useWebWorker: true, // use a web worker for the compression task, offloading the main thread.
    };
    try {
      const compressedFile = await imageCompression(file, options);
      await uploadBytes(reference, compressedFile);
    } catch (error) {
      // TODO: Handle correctly
    }
  };

  const getImageUrl = async () => {
    const path = "user/" + user.id + "/profile_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
      setAddDelete(true);
    } catch (error) {
      setUrl(null);
      setAddDelete(false);
    }
  };

  const sendResetPassword = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, user.email);
      signOut(auth);
      setUser(null);
      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteAccount = async () => {
    setLoading(true);
    await deleteOwnerInvitations();
    await deleteOwnerEvents();
    await deleteProfilePicture();
    await deleteProfileInfo();
    await deleteProfileAccount();
    setUser(null);
    handleClose();
    setLoading(false);
    navigate("/");
  };

  const deleteProfileInfo = async () => {
    try {
      const data = { id: user.id };
      const deleteProfile = httpsCallable(functions, "deleteProfile");
      await deleteProfile(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteProfileAccount = async () => {
    try {
      const data = { id: user.id };
      const deleteAccountInfo = httpsCallable(functions, "deleteAccount");
      await deleteAccountInfo(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteOwnerInvitations = async () => {
    try {
      const data = { id: user.id };
      const deleteInvitations = httpsCallable(
        functions,
        "deleteOwnerInvitations"
      );
      await deleteInvitations(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteOwnerEvents = async () => {
    try {
      const data = { id: user.id };
      const deleteEvents = httpsCallable(functions, "deleteOwnerEvents");
      await deleteEvents(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteProfilePicture = async () => {
    try {
      const data = { id: user.id };
      const deletePicture = httpsCallable(functions, "deleteProfilePictures");
      await deletePicture(data);
    } catch (error) {
      setLoading(false);
    }
  };

  const updateProfileInfo = async () => {
    setLoading(true);
    try {
      const data = mapData();
      const updateProfile = httpsCallable(functions, "updateProfile");
      await updateProfile(data);
      if (file != null && !deletePicture) {
        await uploadPicture(user.id);
        await uploadSmallPicture(user.id);
      } else if (deletePicture) {
        await deleteProfilePicture();
      }
      setUser({
        id: user.id,
        name: profile.name,
        lastName: profile.lastName,
        email: profile.email,
        birthDate: data.birthDate,
        gender: profile.gender,
      });
      setLoading(false);
      navigate("/home");
    } catch (error) {
      setLoading(false);
      showModalError(t("updateProfileError"));
    }
  };

  const mapData = () => {
    const dateValues = profile.birthDate.split("-");
    const date = new Date();
    date.setFullYear(dateValues[0]);
    date.setMonth(parseInt(dateValues[1]) - 1);
    date.setDate(dateValues[2]);
    const dateString = format(date, "dd MM yyyy");

    const jsonProfile = JSON.stringify({
      uid: user.id,
      name: profile.name,
      lastName: profile.lastName,
      email: profile.email,
      gender: profile.gender,
    });
    const data = {
      profile: jsonProfile,
      birthDate: dateString,
      needUpdate: url === null && file === null,
    };
    return data;
  };

  return (
    <div className={styles.content}>
      <div>
        <h1>{t("profileTitle")}</h1>
        <div className={styles.buttons_content}>
          {/* Action buttons */}
          <div className={styles.buttons_group}>
            <button class="solid_button" onClick={showPassModal}>
              {t("updatePassword")}
            </button>
            <button class="border_button" onClick={showDeleteModal}>
              {t("deleteAccount")}
            </button>
          </div>
        </div>
      </div>

      <div className={styles.profile_content}>
        <div className={styles.inner_content}>
          <div className={styles.left_content}>
            <h2>{t("pictureTitle")}</h2>
            {url !== null && <img src={url} />}
            {url === null && <img src="../../img/account_icon.png" />}
            <input type="file" onChange={handleFile}></input>
            {addDelete && (
              <button class="solid_button" onClick={showDeletePictureModal}>
                {t("deletePictureButton")}
              </button>
            )}
          </div>

          <div className={styles.right_content}>
            <h2>{t("informationTitle")}</h2>
            <form onSubmit={handleSubmit}>
              <h2>
                {t("email")} : {user.email}
              </h2>
              <label>{t("name")}</label>
              <input
                value={profile.name}
                type="text"
                name="name"
                required
                onChange={(event) => {
                  setProfile({
                    name: event.target.value,
                    lastName: profile.lastName,
                    email: profile.email,
                    birthDate: profile.birthDate,
                    gender: profile.gender,
                  });
                }}
              ></input>

              <label>{t("lastname")}</label>
              <input
                value={profile.lastName}
                type="text"
                name="lastname"
                required
                onChange={(event) => {
                  setProfile({
                    name: profile.name,
                    lastName: event.target.value,
                    email: profile.email,
                    birthDate: profile.birthDate,
                    gender: profile.gender,
                  });
                }}
              ></input>

              <label>{t("birthDay")}</label>
              <input
                type="date"
                name="birthday"
                value={isoDate}
                onChange={(event) => {
                  setProfile({
                    name: profile.name,
                    lastName: profile.lastName,
                    email: profile.email,
                    birthDate: event.target.value,
                    gender: profile.gender,
                  });
                  setIsoDate(event.target.value);
                }}
              ></input>

              <div>
                <label>{t("gender")}</label>
                <select
                  id="genders"
                  required
                  className={styles.gender_select}
                  defaultValue={user.gender}
                  onChange={(event) => {
                    setProfile({
                      name: profile.name,
                      lastName: profile.lastName,
                      email: profile.email,
                      birthDate: profile.birthDate,
                      gender: event.target.value,
                    });
                  }}
                >
                  <optgroup>
                    <option value="unknown">-</option>
                    <option value="female">{t("woman")}</option>
                    <option value="male">{t("man")}</option>
                    <option value="other">{t("other")}</option>
                  </optgroup>
                </select>
              </div>

              <br></br>
              <button class="solid_button">{t("updateProfile")}</button>

              {loading && <Message message={t("updating")} />}
            </form>
          </div>
        </div>
      </div>

      <Dialog open={modal.show} onClose={handleClose}>
        <DialogTitle>{modal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modal.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading && <label>{t("loading")}</label>}
          {!loading && (
            <>
              <Button onClick={handleClose}>{t("cancel")}</Button>
              {deletePicture && (
                <Button onClick={prepareDeletePicture}>{t("deletePictureButton")}</Button>
              )}
              {!deletePicture && (
                <>
                  {update && (
                    <Button onClick={sendResetPassword}>
                      {t("doSendEmail")}
                    </Button>
                  )}
                  {!update && (
                    <Button onClick={deleteAccount}>
                      {t("doDeleteAccount")}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
