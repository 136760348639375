import { useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";

const MapCameraUpdate = ({location}) => {
    const map = useMap();
  
    useEffect(() => {
      if (!map) return;
      if (location == null) return;
      map.setCenter({lat: location.latitude, lng: location.longitude});
      map.setZoom(15) // Streets zoom
    }, [map, location]);
  
    return <></>;
  };

  export default MapCameraUpdate;