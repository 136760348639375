import moment from "moment";
import { format, getDate } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./EventInformation.module.css";
import React, { useEffect, useState } from "react";

const EventInformation = ({ eventData, setEventData }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [number, setNumber] = useState(0);
  const [minDate, setMinDate] = useState("");
  const [type, setType] = useState("public");
  const [unlimited, setUnlimited] = useState(false);

  const maxGuestNumber = 250;
  const maxNumber = 9999999999999;
  const dateFormat = "MM-dd-yyyy HH:mm";
  const navigationPath = "/home/eventDescription";

  useEffect(() => {
    calculateMin();
  }, []);

  const calculateMin = () => {
    setMinDate(format(new Date(), "yyyy-MM-dd"));
  };

  const getDate = () => {
    const dateValues = date.split("-");
    const currentDate = new Date();
    currentDate.setFullYear(dateValues[0]);
    currentDate.setMonth(parseInt(dateValues[1]) - 1); // Restar 1 al mes porque los meses en JavaScript van de 0 a 11
    currentDate.setDate(dateValues[2]);
    return format(currentDate, dateFormat);
  };

  const getTime = () => {
    const timeValues = time.split(":");
    const date = new Date();
    date.setHours(timeValues[0]);
    date.setMinutes(timeValues[1]);
    return format(date, dateFormat);
  };

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    // Revisamos si el número de invitados no es válido solo cuando no es ilimitado
    if (!unlimited && (number <= 0 || number > maxGuestNumber)) {
      alert(t("maxGuestsError", {number: maxGuestNumber}));
      return;
    }
    if (date === null || time === null) {
      alert(t("dateAndTimeEmpty"));
      return;
    }

    const selectedDate = moment(date + " " + time);
    const currentDate = moment();
    if (selectedDate > currentDate) {
      setInformation();
    } else {
      alert(t("dateAndTimeError"));
    }
  };

  const setInformation = () => {
    setEventData({
      location: eventData.location,
      information: {
        type: type,
        totalGuests: parseInt(number),
        date: getDate(),
        time: getTime(),
        unlimited: unlimited,
      },
    });
    navigate(navigationPath);
  };

  const checkBoxToggle = () => {
    setNumber(unlimited ? maxNumber : 0);
    setUnlimited(!unlimited);
  };

  return (
    <div>
      <h1>{t("eventInfoTitle")}</h1>
      <div className={styles.container}>
        <div className={styles.info_container}>
          <div>
            <label>{t("eventTypeTitle")}</label>
            <select
              id="type"
              className={styles.type_select}
              onChange={(event) => {
                setType(event.target.value);
              }}
            >
              <optgroup>
                <option value="public">{t("public")}</option>
                <option value="private">{t("private")}</option>
              </optgroup>
            </select>
            <p>
              {type === "public" ? (
                <>{t("publicEventDescription")}</>
              ) : (
                <>{t("privateEventDescription")}</>
              )}
            </p>
          </div>

          {!unlimited && (
            <div>
              <label>{t("guestNumberTitle")}</label>
              <input
                placeholder={t("guestNumberPlaceholder", {number: maxGuestNumber})}
                type="number"
                name="number"
                required
                onChange={(event) => {
                  setNumber(event.target.value);
                }}
              />
            </div>
          )}

          <div className={styles.unlimited_container}>
            <div className={styles.check_box}>
              <button onClick={checkBoxToggle}>
                {!unlimited && (
                  <img src="../../img/check_box_icon.png" alt="Imagen" />
                )}
                {unlimited && (
                  <img src="../../img/check_box_fill_icon.png" alt="Imagen" />
                )}
              </button>
              <h2>{t("unlimitedGuestsTitle")}</h2>
            </div>

            <p>{t("unlimitedEventDescription")}</p>
          </div>

          <label>{t("eventDateTitle")}</label>
          <input
            type="date"
            name="date"
            required
            min={minDate}
            onChange={(event) => {
              setDate(event.target.value);
            }}
          />

          <label>{t("eventTimeTitle")}</label>
          <input
            type="time"
            name="time"
            required
            onChange={(event) => {
              setTime(event.target.value);
            }}
          />

          <button class="solid_button" onClick={handleSubmit}>
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventInformation;
