import React from 'react';

const Message = ({message}) => {
    return (
        <div class="message">
            {message}
        </div>
    );
}

export default Message;