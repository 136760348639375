"use client";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import styles from "./MapFilter.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FilteredItem from "../../common/filteredItem/FilteredItem";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MapCameraUpdate from "../../common/MapCameraUpdate";
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";

const MapFilter = ({ user }) => {
  const size = 48;
  const zoom = 15;
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([]);
  const [url, setUrl] = useState(null);
  const [open, setOpen] = useState(null);
  const [owner, setOwner] = useState(null);
  const [picture, setPicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selected, setSelected] = useState(null);
  const [mapCoordinate, setMapCoordinate] = useState(null);
  const [totalAvailable, setTotalAvailable] = useState(null);
  const [locationStatus, setLocationStatus] = useState(false);
  const [userLocation, setUserLocation] = useState({
    loaded: false,
    coordinates: { lat: 0.0, lng: 0.0 },
  });

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      alert(t("geolocationNotAvailable"));
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          loaded: true,
          coordinates: {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          },
        });
        setLocationStatus(true);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLocationStatus(false);
        } else {
          setLocationStatus(false);
        }
      }
    );
  }, []);

  const onPinCick = (item) => {
    setMapCoordinate({
      latitude: parseFloat(item.latitude),
      longitude: parseFloat(item.longitude),
    });
  };

  const updateMapCamera = () => {
    if (!userLocation.loaded && mapCoordinate === null) return;
    setMapCoordinate({
      latitude: userLocation.coordinates.lat,
      longitude: userLocation.coordinates.lng,
    });
  };

  const fetchInfo = async (item) => {
    setLoading(true);
    try {
      await getImageUrl(item.id);
      await fetchOwnerInfo(item);
      await getPictureUrl(item.owner_id);
      if (item.unlimited) {
        setTotalAvailable(null);
      } else {
        await checkTicketsAvailable(item);
      }
      setSelected(item);
      setLoading(false);
      setOpen(true);
    } catch (error) {
      setLoading(false);
    }
  };

  const checkTicketsAvailable = async (item) => {
    const requestData = { id: item.id, total: item.total_guests };
    const ticketsAvailable = httpsCallable(functions, "ticketsAvailableFor");
    try {
      const result = await ticketsAvailable(requestData);
      setTotalAvailable(result.data.data);
    } catch (error) {
      setTotalAvailable(null);
    }
  };

  const sendBound = async (bounds) => {
    const date = format(new Date(), "MM-dd-yyyy HH:mm");
    const data = { id: user.id, bounds: bounds, date: date };
    const filterZone = httpsCallable(functions, "filterZone");
    try {
      const result = await filterZone(data);
      setList(result.data.data);
    } catch (error) {
      setList([]);
    }
  };

  const getImageUrl = async (id) => {
    const path = "event/" + id + "/event_image/event_image.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
    } catch (error) {
      setUrl(null);
    }
  };

  const fetchOwnerInfo = async (item) => {
    const requestData = { id: item.owner_id };
    const downloadInfo = httpsCallable(functions, "downloadProfile");
    try {
      const result = await downloadInfo(requestData);
      const jsonString = JSON.stringify(result.data);
      const json = JSON.parse(jsonString);
      setOwner({
        id: json.id,
        name: json.profile.name,
        lastName: json.profile.last_name,
      });
    } catch (error) {
      alert(t("generalError"));
    }
  };

  const getPictureUrl = async (ownerId) => {
    const path = "user/" + ownerId + "/profile_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setPicture(url);
    } catch (error) {
      setPicture(null);
    }
  };

  const verifyIsInvited = async () => {
    try {
      const data = { eventId: selected.id, userId: user.id };
      const isAlreadyInvited = httpsCallable(functions, "isUserInvitedTo");
      const result = await isAlreadyInvited(data);
      return result.data.data;
    } catch (error) {
      alert(t("generalError"));
    }
  };

  const createTicket = async () => {
    const data = {
      eventData: JSON.stringify(selected),
      guestId: user.id,
      status: "accepted",
      type: "ticket",
    };
    const createTicket = httpsCallable(functions, "createTicket");
    try {
      await createTicket(data);
      setOpen(false);
      setSelected(null);
      setTotalAvailable(null);
      alert(t("ticketReady"));
      setCreating(false);
    } catch (error) {
      setCreating(false);
    }
  };

  const verifyTickets = async () => {
    setCreating(true);
    try {
      if (!selected.unlimited) {
        await checkTicketsAvailable(selected);
        if (totalAvailable <= 0) {
          alert(t("ticketsNotAvailable"));
          setCreating(false);
          return;
        }
      }

      const isInvited = await verifyIsInvited();
      if (isInvited) {
        alert(t("alreadyInvited"));
        setCreating(false);
      } else {
        createTicket();
      }
    } catch (error) {
      alert(t("generalError"));
      setCreating(false);
    }
  };

  return (
    <div className={styles.content}>
      <h1>{t("mapTitle")}</h1>
      <div className={styles.buttons}>
        <button class="solid_button" onClick={updateMapCamera}>
          {t("centerMapButton")}
        </button>
      </div>
      {/* Loader */}
      {!userLocation.loaded && (
        <div className={styles.loader_wrapper}>
          <div className={styles.loader_content}>
            <h1>{t("mapLoadingMessage")}</h1>
            <h2>{t("locationPermissionsTitle")}</h2>
            <p>{t("mapFilterDescription")}</p>
            <img src="../../img/map.png" />
          </div>
        </div>
      )}

      {/* Map */}
      {userLocation.loaded && (
        <div className={styles.map_wrapper}>
          <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            libraries={["places"]}
          >
            <div className={styles.map_filter}>
              <Map
                mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                zoom={zoom}
                center={{
                  lat: userLocation.coordinates.lat,
                  lng: userLocation.coordinates.lng,
                }}
              >
                {userLocation.loaded && (
                  <AdvancedMarker
                    position={{
                      lat: userLocation.coordinates.lat,
                      lng: userLocation.coordinates.lng,
                    }}
                  >
                    <img
                      src="../../img/personal_pin.png"
                      width={size}
                      height={size}
                    />
                  </AdvancedMarker>
                )}

                {list.length > 0 &&
                  list.map((item) => (
                    <AdvancedMarker
                      position={{
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude),
                      }}
                    >
                      <Pin
                        background={process.env.REACT_APP_PIN_BACKGROUND}
                        glyphColor={process.env.REACT_APP_PIN_GLYPH_COLOR}
                        borderColor={process.env.REACT_APP_PIN_BORDER_COLOR}
                      />
                    </AdvancedMarker>
                  ))}
              </Map>
              <MapCameraUpdate location={mapCoordinate} />
              <MapBounds setBounds={sendBound} />
            </div>
          </APIProvider>
          <div className={styles.list_wrapper}>
            {list.length === 0 && (
              <div>
                <h1>{t("emptyFilterList")}</h1>
                <p>{t("emptyFilterListDescription")}</p>
              </div>
            )}
            {list.length > 0 && <h1>{t("filterListTitle")}</h1>}
            {list.length > 0 &&
              list.map((item) => (
                <div className={styles.list_content}>
                  <FilteredItem item={item} onPinCick={() => onPinCick(item)} />
                  {loading && <p>{t("loading")}</p>}
                  {!loading && (
                    <button
                      class="solid_button"
                      onClick={() => {
                        fetchInfo(item);
                      }}
                    >
                      {t("seeMore")}
                    </button>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}

      {/* Dialog */}
      {selected !== null && (
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogContent>
            <div className={styles.modal}>
              {/* background */}
              {url === null && <img src="../../img/background.png" />}
              {url !== null && <img src={url} alt="Imagen" />}

              <div className={styles.modal_content}>
                {/* event info */}
                <div className={styles.modal_content_info}>
                  <h1>{selected.name}</h1>
                  <h2>
                    {t("date")} :{" "}
                    {format(
                      new Date(selected.date.replace("-", "/")),
                      "dd MMM yyyy"
                    )}
                  </h2>
                  <h2>
                    {t("time")} :{" "}
                    {format(new Date(selected.time.replace("-", "/")), "HH:mm")}
                  </h2>
                  <p>{selected.description}</p>
                </div>

                {/* event location */}
                <div className={styles.modal_location}>
                  <h2>Ubicación del evento</h2>
                  <div className={styles.modal_map}>
                    <APIProvider
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      libraries={["places"]}
                    >
                      <Map
                        mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                        zoom={zoom}
                        center={{
                          lat: parseFloat(selected.latitude),
                          lng: parseFloat(selected.longitude),
                        }}
                      >
                        <AdvancedMarker
                          position={{
                            lat: parseFloat(selected.latitude),
                            lng: parseFloat(selected.longitude),
                          }}
                        >
                          {/* add color values on config file */}
                          <Pin
                            background={process.env.REACT_APP_PIN_BACKGROUND}
                            glyphColor={process.env.REACT_APP_PIN_GLYPH_COLOR}
                            borderColor={process.env.REACT_APP_PIN_BORDER_COLOR}
                          />
                        </AdvancedMarker>
                      </Map>
                    </APIProvider>
                  </div>
                  <h2>{selected.place_name}</h2>
                  <p>{selected.address}</p>
                </div>

                {/* creator info */}
                <div className={styles.modal_profile_info}>
                  <h3>{t("eventCreator")}</h3>
                  {picture === null && <img src="../../img/account_icon.png" />}
                  {picture !== null && <img src={picture} />}
                  <h2>
                    {owner.name} {owner.lastName}
                  </h2>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            {creating && <p>{t("loading")}</p>}
            {!creating && (
              <>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button onClick={verifyTickets}>{t("assist")}</Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const MapBounds = ({ setBounds }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    map.addListener("idle", () => {
      getMapBounds();
    });
  }, [map]);

  const getMapBounds = () => {
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast(); // Obtén la esquina noreste
    const sw = bounds.getSouthWest(); // Obtén la esquina suroeste

    setBounds({
      maxLat: ne.lat(), // Latitud más alta
      minLat: sw.lat(), // Latitud más baja
      maxLng: ne.lng(), // Longitud más alta
      minLng: sw.lng(), // Longitud más baja
    });
  };

  return <></>;
};
export default MapFilter;
