import React from "react";
import { format } from "date-fns";
import styles from "./FilteredItem.module.css";

const FilteredItem = ({ item, onPinCick }) => {
  const dateString = () => {
    //This helps to parse the date in Safari
    const dateString = item.date.replace("-", "/");
    const newDate = new Date(dateString);
    const value = format(newDate, "dd MMM yyyy");
    return value;
  };

  const timeString = () => {
    const dateString = item.time.replace("-", "/");
    const newDate = new Date(dateString);
    const value = format(newDate, "HH:mm a");
    return value;
  };

  return (
    <div className={styles.content}>
      <div className={styles.item_header}>
        <img onClick={onPinCick} src="../../img/pin_icon.png" />
        <h2>{item.name}</h2>
      </div>
      
      <h3>{dateString()}</h3>
      <h3>{timeString()}</h3>
      <br></br>
    </div>
  );
};

export default FilteredItem;
