// Siens Software
// Onix

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCiAJ9Ju1dZmlvAru_qTCcY5jtnwXdd9ow",
  authDomain: "siens-onix.firebaseapp.com",
  databaseURL: "https://siens-onix-default-rtdb.firebaseio.com",
  projectId: "siens-onix",
  storageBucket: "siens-onix.appspot.com",
  messagingSenderId: "515301577674",
  appId: "1:515301577674:web:ccd09ed5850e0725103533",
  measurementId: "G-KHK3N2CY1V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const googleProvider = new GoogleAuthProvider();
export const functions = getFunctions(app);
export const storage = getStorage(app);
