import React from "react";
import styles from "./Legal.module.css";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className={styles.main}>
      {/* Header */}
      <header className={styles.header}>
        <a href="/" className={styles.logo}>
          <img src="../../img/logo.png" alt="Logo" />
          <span>Onix</span>
        </a>
      </header>

      <div className={styles.document}>
        <p>
          Este Acuerdo entre usted (“Usted” o el “Usuario” indistintamente) y
          Siens Software S.A de C.V. (“Siens Software” o “nosotros”
          indistintamente) regula el uso del servicio Onix (“Onix”) (y/o
          cualquier otro nombre o servicio que lo(s) sustituya y/o que Siens
          Software determine a su entera discreción en cualquier momento),
          proporcionado por Siens Software por el cual obtendrá acceso a cierto
          contenido, incluyendo sin limitar eventos públicos, privados,
          invitaciones, eventos sociales, material promocional (el “Contenido”).
          Este acuerdo también contempla la forma en que le es permitido el uso
          de los servicios (y acceder al Contenido) proporcionada por y a través
          de nuestra plataforma tecnológica incluyendo las características,
          funcionalidades, software, herramientas u otros materiales que estarán
          disponibles a través de nuestros sitios web, propiedades de internet
          asociadas (ya sea que estén vinculadas por nosotros y/o nuestras
          empresas afiliadas) y cualquier software que le proporcionemos para
          descargar, incluso en sus dispositivos (nuestras aplicaciones móviles,
          aplicaciones de televisor y otras interfaces a disposición del
          Usuario) (todas estas propiedades virtuales, aplicaciones y
          tecnología, se denominará en su conjunto como la “Plataforma”). (La
          Plataforma y cualquier servicio que brindemos a través de todo lo
          anteriormente descrito, incluido el Contenido, se referirá de forma
          conjunta como los “Servicios”).
        </p>
        <h2>1. Servicios. Descripción de las modalidades de los Servicios.</h2>
        <p>
          Si bien es posible que muchos de los Servicios estén a su disposición
          gratuitamente, también es posible que ofrezcamos ciertos Servicios que
          requieren que pague alguna tarifa por el Servicio e impuestos. Si usa
          o se suscribe a cualquiera de los Servicios con suscripción, los
          siguientes términos se aplican a usted, además de los Términos de Uso:
        </p>
        <p>
          A. Servicio en su Modalidad “Software As A Service” (SAAS) (“Onix”).
          En el Servicio Onix el Usuario podrá crear de forma gratuita cierto
          Contenido, a cambio de consumir diversos anuncios comerciales y/o
          información, o lo que en esta sección se señala, como sigue: Destinos
          de Terceros, Publicidad y Promociones. Destino de Terceros (“Destinos
          de Tercero”). La provisión del Servicio Onix, puede contener enlaces a
          sitios web o recursos de terceros, o destinos. Usted no inferirá ni
          asumirá que Siens Software los respalda, opera, controla, es
          responsable o está conectado con estos u otros sitios web, recursos o
          destinos de terceros, incluso si se vinculan a los Servicios e incluso
          si dichos sitios web, recursos o destinos son operados por una empresa
          afiliada con Siens Software. Siens Software podrá proporcionar dichos
          enlaces solo para su conveniencia y Siens Software no se hace
          responsable del contenido, productos o servicios en estos sitios web,
          recursos, destinos o enlaces que se muestran en dichos sitios web ni
          de la información que usted comparta con los mismos. Usted reconoce la
          responsabilidad exclusiva y asume todos los riesgos derivados de su
          uso de sitios web, recursos y destinos de terceros, y libera a Siens
          Software de cualquier responsabilidad u obligación hacia Usted por
          cualquier contenido u otros materiales alojados y servidos desde
          dichos sitios web, recurso o destino y la información que usted
          comparta en los mismos. En virtud de lo anterior, Usted reconoce y
          acepta que Siens Software no es responsable de la información que
          Usted comparta en los sitios web de terceros o recursos o destinos de
          terceros. Publicidad. Usted acepta que Siens Software tiene el derecho
          exclusivo de publicar anuncios y recopilar información accesible a
          través del Servicio Onix. Ocasionalmente, y como resultado de su uso
          del Servicio Onix, es posible que se comunique con, reciba
          comunicaciones de, sea redirigido a, interactúe con, participe en,
          obtenga bienes y servicios de o desde, terceros (los “Anunciantes”)
          como, por ejemplo, nuestros Anunciantes, patrocinadores o socios
          promocionales. Toda esta comunicación, interacción y participación es
          estricta y exclusivamente entre Usted y dichos Anunciantes y Televisa
          no asumirá responsabilidad alguna ante Usted por dichas actividades o
          transacciones (incluyendo entre otros, declaraciones, garantías,
          convenios, contratos u otros términos o condiciones que puedan existir
          entre Usted y el Anunciante o los bienes o servicios que Usted pueda
          comprar, adquirir u obtener de cualquier Anunciante). El Usuario
          reconoce y acepta que los Anunciantes son y serán los únicos
          responsables del contenido del Material Publicitario y del contenido
          del mismo. De igual forma, Usted reconoce y acepta que Siens Software
          no será responsable frente a Usted y/o terceros ni ante cualquier
          autoridad gubernamental del contenido del Material Publicitario,
          cualquier derecho, interés u obligación en y por el Material
          Publicitario, incluyendo, enunciativa mas no limitativamente, las
          marcas, nombres, avisos comerciales, reservas de títulos, diseños,
          formatos o sistemas publicitarios protegidos por derechos de autor,
          registros sanitarios y el contenido en general del Material
          Publicitario, el cual, es de la exclusiva responsabilidad de los
          Anunciantes. Por virtud de lo anterior, los Anunciantes serán los
          únicos responsables en caso que cualquier tercero, ya sea autoridad o
          no, presentara en contra de Siens Software, su empresa controladora,
          sus empresas subsidiarias o filiales, así como en contra de sus
          empleados y funcionarios, con motivo del contenido del Material
          Publicitario.
        </p>
        <p>
          B. Servicio en su Modalidad "Software As A Service" (SAAS) (“Onix”).
          La suscripción al Servicio Onix es de forma periódica (entendiéndose
          la suscripción a su Servicio será renovada de forma automática por
          periodos iguales a los contratados, pudiendo ser estos, mensuales,
          anuales o de otra periodicidad que determine de tiempo en tiempo Siens
          Software), cuyo precio será determinado por Siens Software y podrá
          modificarse en cualquier momento a su entera discreción. Cualquier
          cambio en el precio de la suscripción al Servicio, será informado al
          Usuario con por lo menos 30 (treinta) días naturales de anticipación y
          será cobrado a partir del siguiente periodo de suscripción. El Usuario
          reconoce y acepta que sin perjuicio de que la prestación de los
          Servicios es llevada a cabo por Siens Software S.A de C.V., el
          Servicio Onix puede ser ofrecido a los Usuarios a través de Agentes
          (los “Agentes”) y de distintas maneras, incluidos los planes de
          promociones especiales o las suscripciones con distintas limitaciones.
          Asimismo, estos tipos de suscripciones pueden ser ofrecidos a través
          de Agentes junto con la provisión de sus propios productos y/o
          servicios. Siens Software no es ni será responsable por los productos
          y/o servicios provistos u otorgados por dichos Agentes distintos a los
          Servicios. En algunos casos el precio por la suscripción al Servicio
          Onix podrá variar dependiendo sí Usted se suscribe a través de algún
          Agente o a través de algún otro medio específico de algún tercero, o
          bien si dicho Agente le cobra a Usted el precio de la suscripción al
          Servicio Onix. Siens Software se reserva el derecho a terminar,
          rescindir o modificar de cualquier forma cualesquiera de los planes
          con los que se ofrece el Servicio Onix a través de Agentes. Cobro del
          precio de la suscripción al Servicio Onix. El cobro del precio de la
          suscripción al Servicio Onix se realizará con los impuestos
          correspondientes incluidos, por lo tanto el Usuario acepta que
          comenzará el cobro del precio de la suscripción desde el momento en
          que se haya creado su Cuenta (según dicho término se define más
          adelante) y en consecuencia la aceptación de los Términos y
          Condiciones de este Acuerdo. Si Usted se suscribe al Servicio Onix a
          través de algún Agente u otro medio específico operado por algún
          tercero, el cobro dependerá de dicho Agente y es posible que pueda
          variar o cambiar la forma de cobro. El Servicio Onix se realizará con
          cobros en ciclos de facturación (ejemplo, mensual, anual o de otra
          periodicidad que determine de tiempo en tiempo Siens Software) según
          seleccione al momento de su compra, a partir del día del primer cobro
          de su suscripción del Servicio Onix, en la Forma de Pago que Usted
          haya seleccionado, al precio vigente del Servicio Onix en ese momento,
          y se cobrará independientemente de que Usted haga uso o no del
          Servicio Onix, o bien, que Usted vea, escuche o manipule la totalidad
          o una parte del Contenido. Los pagos no son reembolsables ni se
          otorgarán créditos por periodos no utilizados o utilizados
          parcialmente, ni por Contenido no visualizado (fuera esta
          visualización total o parcial). Procederá el reembolso únicamente
          cuando se actualice el supuesto legal establecido en el artículo 56 de
          la Ley Federal de Protección al Consumidor. Usted no recibirá un aviso
          de parte de Siens Software ni de ningún Agente informándole que su
          período de cobro del precio por la suscripción comenzó, sino que el
          cobro del precio de la suscripción continuará realizándose de manera
          periódica y consecutiva conforme la Forma de Pago que Usted haya
          elegido, a menos de que Usted cancele su suscripción al Servicio Onix
          antes de que termine su periodo vigente de suscripción. Para
          visualizar los detalles específicos de su Cuenta en el Servicio Onix,
          incluido el precio de la suscripción, visite su Cuenta.
          Independientemente que el cobro por la suscripción del Servicio Onix
          se realice directamente mediante alguna de las Formas de Pago, o bien,
          a través de cualquier Agente. Forma de Pago. El Servicio Onix cuenta
          con diversas Formas de Pago, sin embargo, Siens Software se reserva el
          derecho de cambiar, modificar o cancelar cualquier Forma de Pago en
          cualquier momento. Usted puede cambiar la Forma de Pago en cualquier
          momento accediendo su Cuenta y podrá obtener ayuda a través del Centro
          de Servicio al Cliente. Usted es responsable de mantener actualizada
          su información relacionada con su Forma de Pago. Si desea cambiar la
          Forma de Pago, por ejemplo, respecto de la fecha de vencimiento o la
          validez de su tarjeta de crédito o débito, deberá modificar la
          información correspondiente accediendo a su Cuenta. Siens Software
          podrá suspender o denegar el acceso al Servicio Onix en caso de que el
          cobro bajo su Forma de Pago no sea autorizado y no pueda realizarse el
          pago del precio por la suscripción del Servicio Onix. Descargas
          temporales; reproducción sin conexión a internet: Es posible que el
          Servicio Onix proporcione la capacidad de descargar Contenido
          temporalmente a su dispositivo para verlo sin conexión a internet. Por
          ejemplo, en la aplicación móvil de Onix; Si su suscripción permite
          descargas temporales, Usted acepta que: (a) no tiene ningún derecho de
          distribuir dicho Contenido o ver ni usar dicho Contenido de ninguna
          otra manera o medio aparte de los Servicios; (b) es posible que dichas
          descargas estén sujetas a limitaciones, que pueden cambiar de vez en
          cuando, lo que incluye respecto al tipo y la cantidad de Contenido,
          limitaciones de Dispositivos (según dicho término se define en la
          Sección 7 más adelante), acceso a descargas temporales y restricciones
          geográficas para la reproducción; (c) Siens Software no ofrece ninguna
          garantía de que el Contenido estará disponible sin conexión a
          internet. Para evitar dudas, el Contenido descargable ya no estará a
          su disposición si cancela su suscripción o se cambia a una suscripción
          que no incluye dicho Contenido o no permite descargas temporales.
          Trasmisiones simultáneas por internet: El número de trasmisiones
          simultáneas de Contenido por internet al que tenga acceso podrá variar
          en los Servicios, y es posible que requiera verificación del
          dispositivo y la cuenta. Por favor, visite nuestro Centro de Ayuda,
          ayuda.Onix.com, donde encontrará la última información sobre el número
          de trasmisiones simultáneas de Contenido que se permiten en base a los
          términos del Servicio Onix. Facturación. En caso de que Usted requiera
          factura del cobro por la suscripción del Servicio Onix se realiza por
          conducto de algún Agente, es posible que la factura deba ser emitida
          directamente por el Agente por lo que le agradeceremos que se
          comunique al Centro de Servicio al Cliente para dichos efectos.
        </p>
        <h2>2. Cuenta.</h2>
        <p>
          Para poder hacer uso del Servicio Onix, Usted requerirá de una cuenta
          personal de identificación (la “Cuenta”), la cual deberá ser creada
          por Usted, misma que deberá utilizar para autenticarse para acceder a
          los Servicios, así como gestionar cualquier información relacionada
          con sus datos personales, entre lo más relevante.
        </p>
        <p>
          Es importante que brinde y mantenga en todo momento información
          precisa, completa y actualizada para su Cuenta, de lo contrario, Siens
          Software se reserva el derecho para no dar de alta, suspender o
          cancelar su Cuenta en cualquier momento. Adicionalmente, reconoce que
          deberá contar con mayoría de edad y plena capacidad en el Territorio
          para poder crear y/o ser titular de la Cuenta. Si bien los menores de
          edad pueden utilizar los Servicios, sólo podrán hacerlo con la
          intervención, supervisión y aprobación de su padre o tutor, a través
          de la Cuenta de dicho titular. El control de la Cuenta se ejercerá a
          través del uso de la contraseña que hubiera creado, reconoce y acepta
          que en su calidad de creador, titular y Usuario de la Cuenta tiene el
          control y es responsable de proteger la información y confidencialidad
          de la misma, incluyendo su contraseña, así como es responsable del uso
          y restricciones de acceso que haga con los Dispositivos mediante los
          cuales accede a los Servicios, mediante autentificación con su Cuenta.
          Usted no deberá revelar su contraseña ni los datos de su Forma de Pago
          a ningún tercero ni sitios ajenos a los Servicios. Al compartir su
          Cuenta y/o su(s) Dispositivo(s) y/o la contraseña de la Cuenta con
          cualquier tercero o ingresarlos a sitios distintos a los Servicios,
          Usted: (i) asume la responsabilidad de que quien utilice el Servicio
          bajo su Cuenta observe los términos de uso establecidos en este
          Acuerdo, y (ii) reconoce que Usted será responsable por todos los
          actos de quien haga uso de su Cuenta, así como de quien tenga acceso a
          la misma, a los Dispositivos y/o a los datos relacionados con su
          Cuenta. En caso de que Usted utilice Dispositivos públicos o
          compartidos para acceder al servicio, o bien vende o devuelve un
          Dispositivo, Usted deberá cerrar la sesión de su Cuenta al finalizar
          cada uso y desactivar el Dispositivo antes de hacerlo. En caso de
          extravío o robo de su Dispositivo, Usted deberá reportarlo al Centro
          de Servicio al Cliente para que dicho Dispositivo no sea objeto de un
          mal uso. Si no cierra sesión o no desactiva el Dispositivo, otras
          personas podrán posteriormente acceder al servicio a través de su
          Cuenta, e inclusive llegar a acceder a su información personal.
          Algunas funcionalidades de los Servicios a través de ciertos
          Dispositivos podrían estar limitadas. Lo mismo podría suceder en
          algunos casos en que Usted haya contratado el Servicio a través de
          algún Agente. Si Usted descubre que fue víctima de robo de identidad y
          tiene la certeza o sospecha que el delito se llevó a cabo a través de
          su Cuenta, debe notificarlo inmediatamente al Centro de Servicio al
          Cliente de los Servicios, Usted se responsabiliza de denunciar el robo
          al emisor de su tarjeta de crédito y/o débito, así como, de cualquier
          información que haya sido comprometida, para el caso que esa sea la
          Forma de Pago seleccionada, así como a las autoridades competentes.
          Siens Software se reserva el derecho de suspender o cancelar cualquier
          Cuenta en cualquier momento, con o sin aviso a Usted, con el fin de
          protegerse y proteger a sus socios ante lo que pudiera considerarse
          como constitutivo de una o más actividades fraudulentas. Usted está de
          acuerdo en que Siens Software implemente tecnologías que le permiten
          reconocerlo a Usted como el usuario o titular de su Cuenta y darle
          acceso directo a los Servicios sin solicitarle que vuelva a introducir
          ninguna contraseña u otra identificación de Usted al volver a ingresar
          a Onix y Onix.
        </p>
        <h2>3. Cancelación. </h2>
        <p>
          Puede cancelar su suscripción al Servicio Onix en cualquier momento
          antes de que finalice el período de facturación actual o el período de
          prueba, según corresponda, iniciando sesión en su cuenta y siguiendo
          las instrucciones de cancelación (puede visitar nuestro Centro de
          ayuda en ayuda.Onix.com para instrucciones de cancelación). Para
          evitar que se le cobre el próximo período de facturación, debe
          cancelar su suscripción antes de las 11:59 p.m. horario central de
          México (UTC/GMT -6 horas) del día anterior a su próxima fecha de
          facturación recurrente. Las suscripciones cobradas no son
          reembolsables y carecen de valor monetario (por ejemplo, no son una
          cuenta de efectivo o equivalente) y son compras sólo de un derecho no
          exclusivo, revocable, no asignable e intransferible para usar el
          Servicio Onix de conformidad con este Acuerdo, durante el plazo de la
          suscripción. No puede transferir, vender, comprar, permutar o
          intercambiar su suscripción, ni intentar u ofrecer hacerlo. Cualquier
          intento de transferencia será nulo y sin efecto. Salvo que se
          establezca en términos y condiciones adicionales publicados por Siens
          Software a través de los Servicios, o según lo exija la ley aplicable,
          no somos responsables de ningún reembolso o crédito en relación con
          suscripciones modificadas, suspendidas, canceladas o rescindidas. Si
          cancela su suscripción, continuará teniendo acceso al Servicio Onix
          hasta el final de su período de facturación actual. SI CANCELA,
          INCLUSO SI CAMBIA SU FACTURACIÓN DE Siens Software A UN TERCERO QUE
          Siens Software INDIQUE, NO RECIBIRÁ UN REEMBOLSO Y PERDERÁ CUALQUIER
          SERVICIO, REFERENCIA O CRÉDITOS DE TARJETA DE REGALO CANJEADOS. SI
          CANCELA SU SUSCRIPCIÓN DURANTE UNA PRUEBA GRATUITA O MIENTRAS UTILIZA
          UN CÓDIGO PROMOCIONAL U OTROS CRÉDITOS, LA CANCELACIÓN PUEDE SER
          EFECTIVA DE INMEDIATO. La cancelación del Servicio Onix no cancelará
          la Cuenta Onix, en la que aún puede iniciar sesión para usar en
          relación con los Servicios disponibles sin cargo. Si tiene el Servicio
          Onix, debe cancelar su Cuenta Onix si ya no desea tener acceso a Onix.
          Puede cancelar su Cuenta en cualquier momento. En caso de cancelación,
          los Servicios dejarán de estar disponibles para Usted en el momento de
          la cancelación.
        </p>
        <h2>4. Modificación.</h2>
        <p>
          El presente Acuerdo podrá ser modificado en cualquier momento por
          Siens Software, sin necesidad de aviso o notificación previa, en el
          entendido que la última versión del Acuerdo prevalecerá sobre todas
          las versiones publicadas con anterioridad, misma que estará disponible
          en el propio sitio web para su consulta.
        </p>
        <p>
          El uso de los Servicios por parte del Usuario después de cualquier
          modificación al presente Acuerdo constituirá su total aceptación. La
          leyenda “última actualización” indicará cuando se cambió este Acuerdo
          por última vez. Es posible que en cualquier momento, sin previo aviso
          y sin responsabilidad legal, modifiquemos o descontinuemos todo o
          parte del Contenido (incluido el acceso a los Servicios por medio de
          cualquier sitio, aplicación o enlaces de terceros), cobremos,
          modifiquemos o lo exoneremos de cualquier tarifa que se exija para
          usar los Servicios, u ofrezcamos oportunidades a algunos o todos los
          Usuarios. Las tarifas que en su caso apliquen a los Servicios, podrán
          ser modificadas en cualquier momento por Siens Software, previa
          notificación a los Usuarios.
        </p>
        <h2>5. Datos Personales.</h2>
        <p>
          El tratamiento a los datos personales de los Usuarios por el uso de
          los Servicios se encuentra regulado por la Política de Privacidad de
          Siens Software, disponible en https://siens-onix.web.app/privacyPolicy
          (la “Política de Privacidad”).
        </p>
        <h2>6. Contenido.</h2>{" "}
        <p>
          Usted podrá acceder a cierto Contenido que desde su punto de vista
          pudiera considerarse como ofensivo o explícito, aún cuando éste no se
          encuentre específicamente clasificado o identificado como obsceno, con
          lenguaje explícito, indecente o de alguna otra manera ofensivo para
          ciertas audiencias. Usted reconoce y acepta que la visualización de
          los Contenidos es bajo su propio riesgo, liberando a Siens Software de
          cualquier responsabilidad al respecto. Los Servicios son solamente
          para fines informativos, educativos y/o de entretenimiento en general,
          y no se pretende que brinden asesoría, instrucciones ni
          recomendaciones con respecto a ningún asunto, tema, material,
          problema, situación o persona en particular. Por favor, tenga en
          cuenta que no debe depender de ningún Contenido como asesoría (por
          ejemplo, programas legales, financieros o médicos).{" "}
        </p>
        <h2>7. Acceso a los Servicios. </h2>
        <p>
          A fin de obtener acceso a los Servicios, el Usuario deberá usar una
          computadora, dispositivo móvil, reproductor de streaming u otro
          dispositivo que reúna los requisitos del sistema y de compatibilidad
          que se establecen ocasionalmente (cada uno, un “Dispositivo
          Compatible” o “Dispositivo”). El Contenido (incluyendo aspectos de
          éste, por ejemplo: la resolución para programas de televisión o
          películas) puede variar de un Dispositivo a otro, pudiendo afectar
          factores como su ubicación, la configuración de su Dispositivo y la
          velocidad de su conexión de internet. No aseguramos ni garantizamos la
          calidad de su experiencia como televidente en su Dispositivo o usando
          su conexión de internet. La disponibilidad de HD y 4K Ultra HD para
          Contenido apoyado dependerá de una variedad de factores, incluido su
          servicio de internet, la capacidad de sus Dispositivos y si tiene
          acceso a ciertos Servicios con suscripción de Onix. El tiempo que toma
          que usted reproduzca Contenido se basará en varios factores, incluida
          su ubicación, banda ancha de internet, el número de Dispositivos que
          se conectan simultáneamente a la misma red, el Contenido que usted
          seleccionó y la configuración del dispositivo que está usando. Usted
          es el único responsable por cualquier costo asociado a su servicio de
          internet (que por ejemplo, puede incluir tarifas de uso que dependen
          de la cantidad de datos consumidos al tener acceso a los Servicios).
          Sin limitar lo anterior, usted reconoce y acepta que es posible que se
          apliquen cargos estándar de proveedor si obtiene acceso a los
          Servicios con un dispositivo móvil, y que según su plan de servicio
          inalámbrico, es posible que su proveedor de servicio inalámbrico
          imponga cargos por datos y/o de otro tipo, por los que solo usted será
          responsable.
        </p>
        <h2>8. Geobloqueo.</h2>
        <p>
          Es posible que cierto Contenido no esté disponible o se restrinja de
          alguna otra manera en ciertas zonas geográficas. Por tal motivo, es
          posible que usemos diferentes tipos de tecnología y métodos para
          verificar su ubicación geográfica, que se hará conforme a los términos
          de la Política de Privacidad. Por ejemplo, es posible que se requiera
          que habilite el acceso a la ubicación en su Dispositivo Compatible
          para obtener acceso a ciertos Servicios o ver cierto Contenido.
          También es posible que cierto Contenido no esté disponible o se
          restrinja de alguna otra manera, según su Dispositivo Compatible. Es
          posible que los Servicios evolucionen y cambien con el tiempo. Siens
          Software se reserva el derecho de modificar, suspender o interrumpir
          (de manera temporal o permanente) algunos o todos los Servicios
          (incluida la lista de Dispositivos Compatibles) con respecto a algún
          usuario o todos, en cualquier momento, sin notificación ni
          responsabilidad legal para Siens Software. Usted reconoce que no tiene
          ninguna expectativa de continuidad en la disponibilidad los Servicios.
          Siens Software no tendrá responsabilidad legal ante usted por ninguna
          modificación, suspensión o descontinuación de los Servicios. Siens
          Software se reserva el derecho de remplazar o retirar cualquier
          Contenido a su disposición, incluidos programas o canales específicos.
          Siens Software también se reserva el derecho de modificar
          características u otros elementos de los Servicios, incluidas
          características promocionales, interfaces de usuario, características
          y funcionalidades, precios, planes y anuncios, por cualquier razón.{" "}
        </p>
        <h2>9.Materiales de Terceros; Anuncios de Terceros.</h2>
        <p>
          Es posible que ciertas funciones de los Servicios den acceso a
          información, enlaces, indicadores, anuncios, contenido, productos,
          servicios y otros materiales ofrecidos por terceros (“Materiales de
          Terceros”) o permitan la dirección o transmisión de dichos Materiales
          de Terceros por medio de enlaces. Al usar dichas funciones, Usted nos
          da instrucciones de obtener acceso, dirigir y transmitirle los
          Materiales de Terceros correspondientes. Cuando Usted usa un enlace a
          cualquier Material de Terceros dentro de los Servicios, no tenemos la
          obligación de advertirle que ha salido de los Servicios y está sujeto
          a los términos y las condiciones (incluidas su política de privacidad)
          de otro sitio web o destino. Este Acuerdo no rige su uso de otro sitio
          web o destino. No controlamos, respaldamos ni somos responsables por
          ningún Material de Terceros, lo que incluye la exactitud, validez,
          puntualidad, completitud, fiabilidad, integridad, calidad, legalidad,
          utilidad ni seguridad de los Materiales de Terceros, ningún derecho de
          propiedad intelectual en ellos, ni ningún producto o servicio ofrecido
          por los anunciantes vía dichos Materiales de Terceros. Es posible que
          ciertos Materiales de Terceros, entre otras cosas, sean inexactos,
          engañosos o falsos. Nada en este Acuerdo se debe considerar una
          afirmación o garantía por Siens Software con respecto a ningún
          Material de Terceros. No tenemos ninguna obligación de monitorear los
          Materiales de Terceros, y es posible que bloqueemos o desactivemos el
          acceso a algún Material de Terceros (en su totalidad o en parte) por
          medio de los Servicios en cualquier momento. Además, la disponibilidad
          de cualquier Material de Terceros por medio de los Servicios no
          implica nuestro respaldo u afiliación a ningún proveedor de tales
          Materiales de Terceros, y esa disponibilidad no crea ninguna relación
          legal entre usted y dicho proveedor. En cualquier trato y/o acuerdo
          que usted tenga con terceros como anunciantes mientras usa los
          Servicios, Siens Software no tiene responsabilidad legal por ninguna
          pérdida o reclamo que usted pueda tener contra algún tercero. Si Usted
          proporciona información confidencial o personal, Siens Software no es
          responsable por el resguardo y/o uso de dicha información, por lo cual
          recomendamos leer los términos de uso y política de privacidad del
          anunciante. El uso de Materiales de Terceros es bajo riesgo y
          responsabilidad del Usuario, y está sujeto a los términos, condiciones
          y políticas adicionales de dichos Materiales de Terceros (como
          condiciones de servicio o políticas de privacidad de los proveedores
          de dichos Materiales de Terceros). Cualquier reclamo o disputa que
          surja de su uso de cualquier Material de Terceros será exclusivamente
          entre Usted y el tercero correspondiente que le brinda servicios. Bajo
          ninguna circunstancia Siens Software tendrá ninguna responsabilidad
          legal por ningún acto u omisión de ningún tercero que le brinde
          servicios o por ninguna lesión, pérdida o daño que usted incurra como
          resultado del uso de los Materiales de Terceros.{" "}
        </p>
        <h2>10. Licencia.</h2>
        <p>
          Siens Software le otorga al Usuario una licencia personal, no
          exclusiva, limitada, intransferible y con fines no comerciales para
          hacer uso de los Servicios y poder tener acceso al Contenido. Salvo
          por la licencia antes mencionada, Siens Software es dueña y retiene
          todos los derechos de los servicios en todo momento, sus socios, sus
          licenciantes y/o cualquier tercero relacionado, no le otorga ni
          transfiere al Usuario derecho o título alguno de propiedad o
          titularidad sobre el Contenido. Todo uso no autorizado de la licencia
          antes descrita queda estrictamente prohibido, por lo que Siens
          Software se reserva el derecho de bloquear el acceso o abstenerse de
          proveerle al Usuario los Servicios en cualquier momento, así como de
          ejercer las acciones legales que Siens Software estime necesarias para
          salvaguardar sus intereses.
        </p>
        <h2>11. Restricciones de la licencia.</h2>
        <p>
          El Usuario no deberá de manera directa ni indirecta (por ejemplo, por
          medio del uso de cualquier dispositivo, software, sitio de internet,
          servicio basado en internet o aplicación, u otro medio): (a) eliminar,
          modificar, eludir, evitar, interferir o evadir ninguna notificación de
          derecho de autor, marca comercial o de otro tipo sobre propiedad que
          se incluya con el Contenido, ni ningún mecanismo, dispositivo u otra
          medida de protección de contenido o control de acceso de
          administración de derechos digitales asociado al Contenido, lo que
          incluye mecanismos de filtrado geográfico; (b) usar ninguna tecnología
          ni técnica que oculte o disfrace su ubicación cuando tenga acceso a
          los Servicios; (c) copiar, grabar, bajar, captar la trasmisión,
          reproducir, duplicar, archivar, distribuir, subir, publicar,
          modificar, traducir, difundir, presentar, desplegar, vender ni
          exhibir, trasmitir o retrasmitir el Contenido; (d) incorporar el
          Contenido ni trasmitir por internet o retransmitir el Contenido vía
          ningún hardware o aplicación de software, u ofrecer el Contenido por
          medio de marcos o enlaces en internet, ni crear, recrear, distribuir o
          anunciar un fragmento destacado de ninguna porción del Contenido; (e)
          generar actividad comercial usando el Contenido, sea para fines de
          lucro o no; ni (f) crear ningún material que se derive o base en el
          Contenido, lo que incluye montajes, compilaciones y videos similares,
          wallpaper, temas de escritorio, tarjetas de felicitación y mercadería,
          sea para fines de lucro o no. Para evitar ambigüedad, las
          restricciones anteriores se aplican a todo el Contenido, incluido todo
          el texto, gráficos, diseños, interfaces, logotipos, fotografías,
          materiales de audio y video, e instantáneas. 12. Derechos a la
          propiedad de Siens Software. Onix, Onix y los logotipos de Onix y Onix
          son marcas registradas y obras protegidas por el derecho de autor, por
          lo que solamente pueden ser utilizadas con autorización previa y por
          escrito de Siens Software. Las interfaces de usuario, incluidas, entre
          otras, sus gráficos, logos, títulos, iconos de botones, guiones y
          nombres de servicio, son marcas de Siens Software y/o de sus titulares
          y Siens Software se encuentra debidamente autorizada para utilizarlas.
          Otras marcas que aparecen en las interfaces de usuario de Onix y Onix
          son de propiedad de sus respectivos titulares, que pueden o no estar
          vinculados con Siens Software. El Usuario está obligado a utilizar el
          Contenido y la Plataforma única y exclusivamente conforme a lo
          señalado en el presente Acuerdo, así como a cumplir con la legislación
          aplicable en materia de propiedad intelectual e industrial. Todo el
          Contenido y la Plataforma y/u otros materiales provistos por Siens
          Software para acceder al Contenido es de la exclusiva propiedad de
          Siens Software y/o de sus respectivos licenciantes, y están protegidos
          por las correspondientes leyes de derechos de autor y/u otras leyes
          aplicables o tratados internacionales, según corresponda y resulte
          aplicable. El Usuario tiene expresamente prohibido reproducir y/o
          utilizar, total o parcialmente, por cualquier forma o medio, el
          Contenido, la Plataforma y/o información para fines distintos a los
          expresamente autorizados en este Acuerdo, quedando expresamente
          prohibido su uso para fines comerciales o ilegales.{" "}
        </p>
        <h2>13. Normas de conducta.</h2>
        <p>
          El Usuario no podrá usar los Servicios de manera que incumpla ninguna
          ley o norma correspondiente en el Territorio. Sin limitar el carácter
          general de lo anterior, en conexión con su uso de los Servicios, usted
          no debe: Publicar, transmitir ni ofrecer de alguna otra manera por
          medio o en conexión con los Servicios ningún material que sea o pueda
          ser: (a) amenazante, acosador, degradante, lleno de odio ni
          intimidante, o que de alguna otra manera no respete los derechos y la
          dignidad de otros; (b) difamatorio, injurioso, fraudulento o
          perjudicial de alguna otra manera; (c) obsceno, indecente,
          pornográfico o censurable de alguna otra manera; o (d) protegido por
          derechos de autor, marca registrada, secreto comercial, derecho de
          publicidad o privacidad o algún otro derecho de propiedad, sin el
          previo consentimiento expreso y escrito del dueño correspondiente.
          Publicar, trasmitir u ofrecer de alguna otra manera en conexión con
          los Servicios o cuando utilice nuestra Plataforma, algún virus,
          gusano, caballo de Troya, mensaje oculto o Easter egg, bomba de
          tiempo, spyware u otro código, archivo o programa de computación que
          puede ser nocivo o invasivo, o tiene como propósito dañar o secuestrar
          la operación o monitorear el uso de cualquier hardware, software o
          equipo (cada uno, un “Virus”). Publicar o poner a disposición de un
          tercero el Contenido. Usar los Servicios para ningún fin comercial, lo
          que incluye cualquier uso de los Servicios para anunciar o promover
          cualquier producto o servicio. Usar los Servicios para cualquier fin
          que sea fraudulento o perjudicial o ilegal, o que pueda contribuir a
          cualquier actividad fraudulenta, lo que incluye el robo de identidad;
          o fomentar conducta que constituiría un delito o daría lugar a una
          responsabilidad civil. Recopilar información trasgrediendo la Política
          de Privacidad de Siens Software o de alguna otra manera recopilar
          información sobre los Usuarios de los Servicios. Retirar, modificar,
          desconectar, bloquear, ocultar o de alguna otra manera dañar ninguna
          publicidad en conexión con los Servicios. Interferir o alterar la
          operación de los Servicios o cualquier característica particular o
          componente de nuestra Plataforma, o con los servidores o redes que se
          usan para ofrecer los Servicios, lo que incluye la piratería (hacking)
          o daño a cualquier porción de los Servicios; características de
          nuestra Plataforma o por incumplir cualquier requisito, procedimiento
          o política de nuestra Plataforma, servidores o redes. Restringir o
          impedir que ninguna persona use los Servicios, o de alguna otra manera
          interferir con el uso y disfrute de los Servicios por cualquier otra
          persona. Reproducir, modificar, adaptar, traducir, crear obras
          derivadas, vender, alquilar, arrendar, prestar, compartir por un
          tiempo (timeshare), distribuir o de alguna otra manera explotar
          porción de los Servicios, excepto si se autoriza expresamente en el
          presente, sin el previo consentimiento expreso y escrito de Siens
          Software. Someter a ingeniería inversa, revertir la composición o
          integridad de ninguna porción de nuestra Plataforma o cualquier
          tecnología asociada con los Servicios, excepto cuando dicha
          restricción esté prohibida expresamente por las leyes
          correspondientes. Eliminar de los Servicios alguna notificación sobre
          derecho de autor, marca registrada u otros derechos de propiedad. Usar
          tecnología u otros medios para obtener acceso, destacar, enmarcar o
          enlazar porción de los Servicios, lo que incluye eliminar,
          desconectar, eludir o evadir mecanismo de protección del Contenido o
          control de acceso, que tiene como propósito evitar que se bajen,
          capten, enlacen, enmarquen, reproduzcan, distribuyan o se obtenga
          acceso a los Servicios de manera no autorizada o que se incorpore de
          alguna otra manera cualquier porción de los Servicios a cualquier
          producto o servicio, sin el previo consentimiento expreso y escrito de
          Siens Software. Usar algún robot, spider, aplicación de
          búsqueda/recuperación de sitio u otro dispositivo manual o automático
          para recuperar, destacar, extraer (“scrape”), obtener datos o de
          alguna otra manera reunir u obtener acceso sin autorización a los
          Servicios (incluyendo a cualquier característica específica de nuestra
          Plataforma), o reproducir o evadir la estructura o presentación de
          navegación de los Servicios (y la tecnología asociada de la
          Plataforma), sin el previo consentimiento expreso y escrito de Siens
          Software. Bajar o guardar el Contenido, excepto como lo permita
          cualquier función de los Servicios que pongamos a su disposición. El
          Usuario es responsable por obtener, mantener y pagar por todo el
          hardware y todas las telecomunicaciones y otros servicios necesarios
          para usar los Servicios.{" "}
        </p>
        <h2>
          14. Condiciones que se aplican a terceros que son proveedores de
          plataformas; licencias de terceros.
        </h2>
        <p>
          Si Usted obtiene acceso o baja los Servicios a través de una tienda de
          aplicaciones, plataforma o punto de acceso donde se ofrecen nuestros
          Servicios ( “Terceros que son Proveedores de Plataforma”), incluidos,
          por ejemplo, Apple, Inc., Amazon.com, Inc., Google, Inc., Microsoft
          Corporation, Samsung Electronics America, Inc., dichos Terceros que
          son Proveedores de Plataforma serán terceros que se benefician de este
          Acuerdo. Sin embargo, ningún Tercero que es Proveedor de Plataforma es
          parte de este Acuerdo ni tendrá ninguna obligación de ofrecer
          mantenimiento y/o apoyo para los Servicios. Ningún Tercero que es
          Proveedor de Plataforma, sino que Siens Software es exclusivamente
          responsable por los Servicios. El acceso por Usted a los Servicios
          usando cualquier tienda de aplicaciones o plataforma de Terceros está
          sujeto a los términos de uso contenidos en los términos de servicio de
          dicha Plataforma de Terceros.{" "}
        </p>
        <h2>15. Comentarios.</h2>
        <p>
          Usted podrá publicar reseñas o comentarios en relación con el
          Contenido que se le ofrece y autoriza a TelevisaUnivison para que use
          los mismos a su entera discreción. Toda opinión, asesoramiento,
          declaración, servicio, oferta u otra información que forme parte del
          contenido expresado o provisto por terceros a través de los Servicios
          corresponden a sus respectivos autores o productores y no reflejan la
          opinión, el asesoramiento, la declaración, el servicio, la oferta u
          otra información de Siens Software o de sus accionistas, directores,
          ejecutivos, empleados o licenciantes. Siens Software y sus
          accionistas, directores, ejecutivos, empleados o licenciantes no serán
          responsables en ningún caso por la pérdida o daños provocados por
          confiar en la información obtenida a través de Onix y Onix. Es
          responsabilidad del Usuario evaluar la información, opinión, consejo u
          otros contenidos disponibles a través de los Servicios.{" "}
        </p>
        <h2>16. Promociones.</h2>
        <p>
          Eventualmente Siens Software podrá poner en práctica distintos tipos
          de promociones en beneficio de los Usuarios, las cuales se sujetarán
          al presente Acuerdo, o bien a los términos y condiciones específicos
          de cada promoción ( las “Promociones”). Las promociones se ofrecen a
          nuestro exclusivo criterio y podemos dejar de ofrecer una promoción en
          cualquier momento.{" "}
        </p>
        <h2>17. Exención de garantías.</h2>
        <p>
          Los Servicios se ofrecen "en el estado en que se encuentran", con
          todas sus fallas y sin garantías de ningún tipo. Siens Software y sus
          respectivos licenciantes no emiten declaraciones ni garantías de
          ningún tipo respecto de la suscripción, la calidad con la que se
          visualiza el Contenido y/o la Plataforma. Siens Software no
          manifiesta, declara ni garantiza que Usted podrá utilizar los
          Servicios sin interrupciones o sin errores, y Usted reconoce que Siens
          Software podrá eliminar o modificar de cualquier manera cualesquiera
          aspectos, funcionalidades u otros elementos sin aviso para usted ni
          responsabilidad o indemnización alguna a cargo de Siens Software.
          Siens Software manifiesta expresamente que no asume responsabilidad
          alguna por el uso de los Contenidos, la Plataforma y/o los
          Dispositivos. Sin perjuicio de la exclusión de garantías y las
          limitaciones a la responsabilidad establecidas a lo largo del presente
          Acuerdo, Siens Software y sus licenciantes no asumen responsabilidad
          alguna respecto de lo siguiente: (i) errores u omisiones en el
          Contenido o en la Plataforma; (ii) recomendaciones o asesoramiento del
          Centro de Servicio al cliente, (iii) cualquier falla o interrupción en
          la disponibilidad de los Servicios; (iv) la provisión o transmisión de
          cualquier Contenido y/o la Plataforma, (v) cualquier pérdida o daño
          y/o perjuicio que surja del uso de los Servicios y/o la Plataforma y/o
          el Contenido, incluidos los daños o pérdidas causados por la descarga
          y/o uso de la Plataforma, la descarga o uso de cualquier otro software
          o aplicación o cualquier otra conducta de los usuarios y/o la
          Plataforma; y/o (vi) el Material Publicitario de los terceros, su
          contenido y los bienes y servicios asociados con los mismos. En la
          medida en que lo permita la legislación aplicable, Siens Software y
          sus licenciantes excluyen cualquier declaración o garantía, incluyendo
          sin limitar, las garantías de comerciabilidad, de calidad, de aptitud
          para cualquier fin en particular o de respeto de los derechos de
          autor. Siens Software tampoco declara ni garantiza que la información
          a la que se accede a través de Onix y Onix sea precisa, completa o
          actualizada, ni declara ni garantiza que el uso que se haga de los
          Servicios esté libre de interrupción, pérdida, corrupción, ataque,
          Virus, interferencias, piratería informática o cualquier otra
          intrusión contra la seguridad y no asume ninguna responsabilidad en
          relación con estos hechos. La información que Siens Software provea en
          forma oral o escrita no generará ninguna garantía ni declaración que
          pueda generar responsabilidad para éste o para sus partes vinculadas.
          En ningún caso Siens Software, su controladora, filiales o
          subsidiarias y sus respectivos accionistas, directores, ejecutivos,
          empleados o licenciantes serán responsables (en forma individual o en
          su conjunto) ante Usted o cualquier otro Usuario por cualquier tipo de
          daño que surgiera de la pérdida de uso, datos o cualquier otro daño o
          pérdida de naturaleza comercial, ya sea que se hubiera advertido o no
          la posibilidad del daño y cualquier responsabilidad que pudiera surgir
          con el uso del Contenido y/o de la Plataforma. Siens Software no
          otorga ninguna declaración o garantía respecto de los Dispositivos ni
          la compatibilidad o configuración de los Dispositivos con Onix, así
          como respecto de su conexión a internet. Los distintos contratos de
          licencia para usuario final de software de terceros que usted haya
          acordado a fin de acceder al Contenido pueden contener exclusiones
          adicionales o limitaciones a la responsabilidad, debiendo revisarlas
          antes de utilizar Onix.{" "}
        </p>
        <h2>18. Limitación de responsabilidad legal.</h2>
        <p>
          En ningún caso Siens Software, su controladora, filiales o
          subsidiarias y sus respectivos accionistas, directores, ejecutivos,
          empleados o licenciantes serán responsables (en forma individual o en
          su conjunto) ante Usted o cualquier Usuario por cualquier tipo de daño
          que sugriera de la pérdida de uso, datos o cualquier otro daño o
          pérdida de naturaleza comercial, ya sea que se hubiera advertido o no
          la posibilidad de daño y cualquier responsabilidad que pudiera surgir
          con el uso de los Servicios, el Contenido y/o la Plataforma. La
          responsabilidad total de Siens Software por cualquier daño o pérdida
          provocada por el uso o la imposibilidad de utilizar Onix (incluido el
          Contenido y la Plataforma) no podrá exceder en ningún caso el monto
          del precio por la suscripción de los Servicios. Las limitaciones
          anteriores se aplicarán incluso si el resarcimiento previsto no cumple
          su última finalidad. Si alguna autoridad competente declarara la
          invalidez de alguna disposición de esta sección o cualquier otra del
          Acuerdo, sólo las secciones declaradas invalidás dejarán de ser
          aplicables bajo el presente Acuerdo. Siens Software no otorga ninguna
          declaración o garantía respecto de los Dispositivos ni la
          compatibilidad o configuración de los dispositivos con los Servicios,
          así como respecto de su conexión a internet. Los distintos contratos
          de licencia para usuario final de software de terceros que Usted
          hubiera acordado a fin de acceder a los Servicios pueden contener
          exclusiones adicionales o limitaciones a la responsabilidad, debiendo
          revisarlas antes de utilizar los Servicios.{" "}
        </p>
        <h2>19. Indemnización.</h2>
        <p>
          Usted acepta, indemnizar y sacar en paz y a salvo a Siens Software su
          controladora, filiales o subsidiarias y sus respectivos accionistas,
          directores, ejecutivos, empleados o licenciantes serán responsables
          (en forma individual o en su conjunto) de todo reclamo, proceso
          administrativo, demanda, juicio, procedimiento, fallo, orden, daños,
          responsabilidad legal, pérdida y/o menoscabo, costos, gastos y
          honorarios (incluidos los honorarios de abogados) que surjan o se
          relacionen con (a) los Servicios (incluyendo sin limitar todos los
          Comentarios); y (b) cualquier incumplimiento o presunto incumplimiento
          al presente Acuerdo o cualquier ley aplicable a los mismos.{" "}
        </p>
        <h2>20. Rescisión.</h2>
        <p>
          Siens Software se reserva el derecho de terminar o rescindir el
          presente Acuerdo y los Servicios, así como de restringir, bloquear el
          uso que Usted haga de los Servicios sin aviso, con o sin causa, y sin
          responsabilidad alguna, en el entendido que Siens Software estará
          facultada para desactivar o eliminar su Cuenta de inmediato, así como
          todos los materiales asociados a ésta, sin ninguna obligación de
          proporcionar al Usuario el acceso adicional a dichos materiales.{" "}
        </p>
        <h2>21. Jurisdicción y Ley Aplicable en el Territorio.</h2>
        <p>
          Al aceptar estos Términos de Uso, y al utilizar el Servicio en México,
          Usted conviene expresa e irrevocablemente en que cualquier
          reclamación, controversia o conflicto relacionado con el consumo y
          prestación de los Servicios deberá ser resuelto en la vía
          administrativa ante la Procuraduría Federal del Consumidor. Sin
          perjuicio de lo anterior, el Usuario reconoce y se obliga a que
          cualquier otro asunto derivado de la interpretación, cumplimiento o
          incumplimiento del presente Acuerdo se regirá e interpretará de
          acuerdo con las leyes federales aplicables de México y se sujetará a
          la jurisdicción de los tribunales competentes en la Ciudad de México,
          renunciando expresa e irrevocablemente a cualquier otra jurisdicción
          que le pudiera corresponder por razón de su domicilio presente o
          futuro o por alguna otra causa.{" "}
        </p>
        <h2>22. Información o quejas. </h2>
        <p>
          Si Usted tiene alguna pregunta o queja respecto a los Servicios, por
          favor, mande un mensaje electrónico a ayuda@Onix.com. Tenga en cuenta
          que las comunicaciones electrónicas no son necesariamente seguras; por
          lo tanto, no deberá incluir información sobre tarjetas de crédito u
          otra información confidencial en la correspondencia electrónica que
          nos mande.{" "}
        </p>
        <h2>23. Controles de exportación.</h2>
        <p>
          El Usuario es responsable por el cumplimiento de los controles de
          exportación de Estados Unidos de Norteamérica y por toda trasgresión
          de dichos controles, lo que puede incluir embargos y otras normas y
          reglamentaciones federales que restringen las exportaciones. Usted
          afirma, garantiza y se compromete que no (a) es residente ni
          ciudadano, ni está ubicado en ningún país sujeto a un embargo
          gubernamental u otra restricción de Estados Unidos de Norteamérica, ni
          que ha sido designado por el gobierno de Estados Unidos de
          Norteamérica como un país que “apoya a terroristas”; ni (b) está en
          ninguna lista de usuarios finales restringidos del gobierno de Estados
          Unidos. Usted acuerda no exportar, reexportar ni transferir de manera
          directa o indirecta, ninguno de los Servicios ni ningún dato técnico
          de Estados Unidos de Norteamérica adquirido en alguno de los
          Servicios, ni ningún producto que utiliza dichos datos ni que
          trasgreda las leyes y reglamentos de exportación de Estados Unidos de
          Norteamérica.{" "}
        </p>
        <h2>24. Misceláneos.</h2>
        <p>
          Este Acuerdo no crea, ni se debe considerar que crea una sociedad, una
          empresa conjunta, una relación entre empleado y empleador o entre
          otorgante de licencia y licenciatario entre Usted y Siens Software. Si
          se encuentra que alguna cláusula de este Acuerdo es ilegal, inválida o
          es imposible verificar su cumplimiento por alguna razón, se
          considerará dicha sección separable de este Acuerdo y esto no afectará
          la validez y vigencia de todas las demás secciones. Siens Software se
          reserva el derecho de ceder una parte o la totalidad del presente
          Acuerdo, a su entera discreción y sin necesidad de aviso, notificación
          y/o autorización del usuario. Usted no podrá ceder, en su totalidad o
          en parte, ni transferir, ni sublicenciar el presente Acuerdo a ningún
          tercero. Ninguna exención de responsabilidad para alguna de las partes
          por trasgresión o incumplimiento bajo este Acuerdo se considerará una
          exención de una trasgresión o incumplimiento previo o posterior. Todo
          título, sección o subtítulo en este documento es para la conveniencia
          de las partes y de ninguna manera define ni explica una sección. Todos
          los términos definidos en singular tendrán el mismo significado si se
          usan en plural, donde sea apropiado, salvo especificación en
          contrario. Todo uso del término “incluido” o variaciones de este en el
          presente Acuerdo será entendido como si fuera seguido de la frase “sin
          limitación”. Este Acuerdo, incluidos todos los términos y condiciones
          aquí incorporados, constituye el Acuerdo entre usted y Siens Software
          relativo a este tema, y tiene precedencia sobre cualquier otro acuerdo
          o entendimiento previo entre usted y Siens Software respecto a este
          tema. Las notificaciones que le mandemos podrán ser mediante
          publicaciones efectuadas dentro de los mismos Servicios o por correo
          electrónico (incluidos en cada caso vía enlaces), o por correo
          regular. Sin limitación alguna, una versión impresa de este Acuerdo y
          de cualquier otra notificación enviada por vía electrónica es
          admisible en procesos judiciales o administrativos que se basen o
          estén relacionados a este Acuerdo, en la misma medida y sujeta a las
          mismas condiciones de otros documentos y registros comerciales
          generados y mantenidos de forma impresa. Siens Software no será
          responsable si no puede cumplir alguna obligación debido a una causa
          fuera de su control.
        </p>
      </div>

      {/* Footer */}
      <footer className={styles.footer}>
        <div className={styles.footer_content}>
          <p>{t("contact")}</p>
          <p>siens.software.comments@gmail.com</p>
        </div>
        <div className={styles.footer_links}>
          <a href="/terms">{t("terms")}</a>
          <a href="/policy">{t("policy")}</a>
          <p className={styles.org}>Sīəns &copy;</p>
        </div>
      </footer>
    </div>
  );
};

export default Terms;
