import React from "react";
import styles from "./Legal.module.css";
import { useTranslation } from "react-i18next";

const Policy = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.main}>
      {/* Header */}
      <header className={styles.header}>
        <a href="/" className={styles.logo}>
          <img src="../../img/logo.png" alt="Logo" />
          <span>Onix</span>
        </a>
      </header>

      <div className={styles.document}>
        <h2>Siens Software - Aviso Global de Privacidad</h2>
        <h3>Última modificación: 07 de 12 de 2023</h3>
        <p>
          Siens Software y sus filiales (colectivamente,"Siens
          Software","nosotros", "nos" o "nuestro") respetan su privacidad. Este
          Aviso Global de Privacidad ("Aviso de Privacidad" o "Aviso") describe
          nuestras prácticas en relación con la información que recopilamos a
          través de nuestros sitios web, aplicaciones u otros servicios en línea
          (colectivamente, "Servicios”). En concreto, este Aviso de Privacidad
          explica cómo recopilamos, utilizamos y divulgamos la información de
          los usuarios de nuestros Servicios("Usuarios" o "Usted").
        </p>
        <h3>1. INFORMACIÓN PERSONAL QUE PODEMOS RECOPILAR</h3>
        <p>
          A. Información que Usted Proporciona Información de Registro y Perfil.
          Cuando usted se registra para obtener una cuenta en nuestros
          Servicios, se inscribe para recibir notificaciones o actualizaciones,
          o participa en nuestras encuestas, podemos recibir su nombre, nombre
          de usuario, contraseña y/o dirección de correo electrónico.
          Comunicaciones. Si se pone en contacto con nosotros directamente, por
          ejemplo cuando se pone en contacto con nuestro Equipo de Atención al
          Cliente, podemos recibir su nombre, dirección de correo electrónico y
          otra información que decida proporcionarnos. Si se suscribe a uno de
          nuestros boletines, podemos recibir información suya, como su
          dirección de correo electrónico. Información de Compra. Si realiza una
          compra o se suscribe a través de nuestros Servicios, la información
          relacionada con el pago, como la tarjeta de crédito u otra información
          financiera, la recibe nuestro procesador de pagos externo en nuestro
          nombre. También podemos recibir su historial de compras, dirección de
          entrega y otra información relacionada con sus operaciones. Contenido
          del Usuario. Recibimos cualquier información o contenido que decida
          publicar en nuestros Servicios, como comentarios u otras opiniones.
        </p>
        <p>
          B. Información que recopilamos cuando utiliza nuestros servicios
          Información sobre el dispositivo. Podemos recibir información sobre el
          dispositivo y el software que utiliza para acceder a nuestros
          Servicios, incluida dirección IP, el ID del dispositivo, el tipo de
          navegador web, la versión del sistema operativo, el operador y el
          fabricante del teléfono, los identificadores del dispositivo, los
          identificadores de publicidad móvil y los tokens de notificaciones
          push. Información de uso. Para ayudarnos a comprender cómo utiliza
          nuestros Servicios Ayudarnos a mejorarlos, podemos recibir
          automáticamente información sobre sus interacciones con nuestros
          Servicios, incluidos los vídeos y contenidos que visualiza en nuestros
          Servicios, cómo interactúa con nuestros Servicios, las páginas u otros
          contenidos que visualiza, los datos de uso cuando utiliza nuestras
          aplicaciones, las búsquedas que realiza, las compras que efectúa, sus
          comentarios y las fechas y horas de sus visitas. Información de
          Localización. Cuando utiliza nuestros Servicios, podemos recibir
          información sobre su ubicación, que puede incluir la ciudad, el
          estado, el código postal u otra información de ubicación general
          deducida de su dirección IP o derivada de señales Wi-Fi o GPS.
          Recopilamos esta información con fines tales como identificar a
          nuestro público, adaptar nuestros Servicios a su ubicación y enviarle
          marketing y publicidad relevantes. Información procedente de cookies y
          tecnologías similares. Nosotros y nuestros socios terceros podemos
          recibir información proporcionada por cookies, etiquetas de píxel o
          tecnologías similares. Nuestros socios externos, como socios
          analíticos y publicitarios, pueden utilizar estas tecnologías para
          recopilar información sobre sus actividades en línea a lo largo del
          tiempo y a través de diferentes servicios. Podemos utilizar tanto
          cookies de sesión como cookies persistentes. Una cookie de sesión
          desaparece después de cerrar el navegador. Una Cookie Persistente
          permanece después de que usted cierra su navegador y podrá ser
          utilizada por navegador en visitas posteriores a nuestros Servicios.
          Ofrecemos controles que le permiten modificar la configuración de las
          cookies. Para acceder a dichos controles, visite el menú/pie de página
          de privacidad de nuestras propiedades digitales. Si usted reside en
          uno de los estados de EE.UU. que nos obligan a respetar las señales
          preferencia de exclusión voluntaria basadas en su navegador, y si pone
          en práctica dichas señales, no venderemos Datos Personales ni
          procesaremos Datos Personales para publicidad dirigida (o "publicidad
          conductual de contexto cruzado" para los residentes en California)
          recopilada a través de ese navegador. Puede implementar estas señales
          a través de la configuración de su navegador para los navegadores que
          las ofrecen. Consulte su navegador para obtener información e
          instrucciones adicionales. C. La información que recibimos de terceros
          Información Obtenida de Terceros. Recibimos información como datos
          geodemográficos, nivel de ingresos e información profesional y
          relacionada con el empleo fuentes de terceros como empresas de redes
          sociales, bases de datos disponibles públicamente, socios de
          marketing, socios publicitarios, servicios de medición y motores de
          recomendación que nos ayudan a actualizar o complementar la
          Información Personal que mantenemos sobre usted.{" "}
        </p>
        <h3>2. CÓMO USAMOS SU INFORMACIÓN PERSONAL</h3>
        <p>
          Podemos utilizar su información personal para: Proporcionar, mantener,
          mejorar y potenciar nuestros Servicios: Proporcionar la funcionalidad
          de nuestros Servicios y mejorar los Servicios. Comunicarnos:
          Proporcionarle nuestros boletines informativos, enviarle correos
          electrónicos, mensajes de texto y prestarle asistencia al cliente de
          cualquier otro modo. Comercializar y publicitar: Desarrollar y
          proporcionar contenido promocional y publicitario que creamos que
          pueda ser relevante, valioso o de algún otro modo de interés para
          usted. Publicar en servicios de terceros: Permiten publicar contenidos
          en servicios de terceros, como redes sociales. Personalizar:
          Personalizar su experiencia, por ejemplo recordando su idioma y
          preferencias de visualización, y proporcionando contenidos y
          recomendaciones a medida. Facilitar Promociones: Permitirle participar
          en sorteos, concursos u otras promociones. Desidentificar y agregar
          información personal: Generar datos desidentificados o agregados que
          podemos utilizar para fines tales como comprender las preferencias e
          intereses de nuestros usuarios. Facilitar las Operaciones: Procesar
          sus operaciones de pago y cumplir con sus pedidos. Prevenir el fraude
          y los incidentes de seguridad: Detectar, investigar, proteger y
          prevenir el fraude y otros riesgos legales o de seguridad de la
          información. Cumplimiento: Para fines de cumplimiento, incluida la
          aplicación de nuestras Condiciones de uso u otros derechos legales, o
          según lo requieran las leyes y reglamentos aplicables o lo solicite
          cualquier proceso judicial o agencia gubernamental. Otros fines: Para
          otros fines que le notifiquemos o para los que usted dé
          consentimiento, en cumplimiento de la legislación aplicable.{" "}
        </p>

        <h3>3. CÓMO DIVULGAMOS SU INFORMACIÓN PERSONAL </h3>

        <p>
          No divulgamos la información que recopilamos de usted o sobre usted
          excepto en los casos descritos a continuación o que se le comuniquen
          en el momento de la recopilación. Prestadores de servicios. Podemos
          revelar cualquier información que recibamos con los Proveedores de
          Servicios contratados en relación con la prestación de nuestros
          Servicios. Exigimos que nuestros Proveedores de Servicios utilicen su
          Información Personal Compartida por nosotros únicamente para
          prestarnos servicios y que mantengan la confidencialidad, seguridad e
          integridad de dicha Información Personal. Socios que operan servicios
          de Siens Software: Podemos ofrecer ciertos servicios de marca Siens
          Software en asociación con terceros. Si usted se inscribe o participa
          de alguna otra manera en estos servicios de la marca Siens Software,
          podemos divulgar su Información Personal a los terceros que nos ayudan
          a operar ese servicio. Estos terceros pueden utilizar sus Datos
          Personales para el funcionamiento del servicio, así como para sus
          propios fines comerciales. Información sobre el contenido. Podemos
          compartir información sobre el contenido que ve (1) a petición suya
          (por ejemplo, si solicita la integración de su cuenta Onix con un
          dispositivo de terceros), o (2) según sea necesario cuando usted pasa
          o comparte información con otras cuentas que pueda tener (por ejemplo,
          si desea compartir información de visualización en un acuenta de redes
          sociales), con el fin de proporcionar y mejorar los Servicios, y
          recomendarle mostrarle contenido basado en lo que ha visto en nuestros
          Servicios. Información sobre el contenido. Podemos compartir
          información sobre el contenido que ve (1) a petición suya (por
          ejemplo, si solicita la integración de su cuenta Onix con un
          dispositivo de terceros), o (2) según sea necesario cuando usted pasa
          o comparte información con otras cuentas que pueda tener (por ejemplo,
          si desea compartir información de visualización en una cuenta de redes
          sociales), con el fin de proporcionar y mejorar los Servicios, y
          recomendarle o mostrarle contenido basado en lo que ha visto en
          nuestros Servicios. Marketing y socios comerciales. Revelamos
          información a terceros, como nuestros socios comerciales, empresas de
          investigación de mercado y socios de marketing, para que puedan
          enviarle comunicaciones de marketing. Aplicaciones de terceros:
          Nuestros Servicios permiten y/o contienen enlaces a determinadas
          aplicaciones de terceros (por ejemplo, el botón "Me gusta" de Facebook
          y otros plug-ins, herramientas y API de redes sociales) para mejorar
          su experiencia en los Servicios. Cuando usted interactúa con la
          funcionalidad de un tercero, ese tercero puede recopilar información
          sobre su uso de los Servicios. Antes de utilizar aplicaciones de
          terceros, debe revisar y consultar sus avisos de privacidad.
          Patrocinadores y Copatrocinadores: Podemos llevar a cabo eventos,
          sorteos, concursos, regalos y/u otras promociones con uno o más
          patrocinadores o copatrocinadores externos. Si usted participa en
          alguno de estos eventos o promociones, la Información Personal que
          recopilamos de usted en el contexto de su participación puede ser
          recibida o compartida con nuestros patrocinadores o copatrocinadores.
          Socios analíticos. Utilizamos servicios analíticos como Google
          Analytics para recopilar y procesar determinados datos analíticos.
          Puede obtener más información sobre las prácticas de Google en
          https://www.google.com/policies/privacy/partners. Utilizamos la
          aplicación invisible reCAPTCHA de Google en nuestros Servicios para
          protegerlos contra el spam y otros abusos automatizados. La
          herramienta reCAPTCHA puede hacer uso de cookies, así como de otra
          información como la dirección IP, la fecha, la hora, el idioma, el
          tamaño y la resolución de la pantalla y los movimientos del ratón. El
          uso de la herramienta reCAPTCHA y cualquier información recopilada a
          través de la herramienta están sujetos a la política de privacidad de
          Google, disponible en https://policies.google.com/privacy, y a las
          condiciones de servicio de Google, disponibles en
          https://policies.google.com/terms?hl=en Socios publicitarios.
          Trabajamos con socios publicitarios externos para mostrarle anuncios
          que puedan interesarle, y podemos revelar información a terceros para
          permitirla publicidad dirigida. Algunos de nuestros socios
          publicitarios son miembros de la Network Advertising Initiative
          https://optout.networkadvertising.org o de la Digital Advertising
          Alliance (https://optout.aboutads.info). Si no desea recibir anuncios
          personalizados, visite sus páginas de exclusión para saber cómo puede
          dejar de recibir anuncios personalizados en Internet de las empresas
          asociadas. Puede acceder a cualquier configuración ofrecida por su
          sistema operativo móvil para limitar el seguimiento de anuncios, o
          puede instalar la aplicación móvil AppChoices para obtener más
          información sobre cómo puede optar por no recibir anuncios
          personalizados en aplicaciones móviles. Otros usuarios. Tenga en
          cuenta que cualquier información o contenido que decida publicar en
          los Servicios, como comentarios, se mostrará en los Servicios y podrá
          ser vista por otros Usuarios de forma predeterminada. No somos
          responsables del uso que los demás Usuarios hagan de la información
          disponible, por lo que usted debe considerar cuidadosamente si publica
          o no y qué publica o cómo se identifica en los Servicios. Medición.
          Revelamos información a socios de medición como Nielsen. Puede optar
          por no participar en los estudios de mercado realizados por Nielsen
          siguiendo las instrucciones de la Declaración de privacidad de Nielsen
          sobre mediciones digitales,
          https://www.nielsen.com/us/en/legal/privacy-statement/digital-measurement.
          Según lo exigido por la ley y divulgaciones similares. Podremos
          acceder a sus datos, conservarlos y divulgarlos si lo consideramos
          necesario o apropiado para (a) cumplir con solicitudes de aplicación
          de la ley y procesos legales, como una orden judicial o citación;
          (b)responder a sus solicitudes; o (c) proteger sus derechos, propiedad
          o seguridad, los nuestros o los de otros. Para evitar cualquier duda,
          la divulgación de su información puede producirse si usted publica
          cualquier contenido censurable en o a través de los Servicios. Fusión,
          venta u otras transferencias de activos. Podemos transferir su
          información a proveedores de servicios, asesores, posibles socios
          transaccionales u otros terceros en relación con la consideración,
          negociación o finalización de una operación corporativa en la que
          seamos adquiridos o fusionados con otra empresa o vendamos, liquidemos
          o transfiramos todos o parte de nuestros activos. El uso de su
          información después de cualquiera de estos eventos se regirá por las
          disposiciones de este Aviso de Privacidad en vigor en el momento en
          que se recogió la información aplicable. Consentimiento. También
          podemos divulgar su información con su permiso.
        </p>
        <h3>4. OPCIONES</h3>
        <p>
          Preferencias de divulgación. Darse de baja de las comunicaciones
          promocionales y de marketing de Siens Software. Para darse de baja de
          las comunicaciones promocionales y de marketing de Siens Software,
          siga las siguientes instrucciones: Para darse de baja de los correos
          electrónicos de marketing, puede utilizar los medios de exclusión
          descritos en el correo electrónico de marketing (por ejemplo,
          utilizando un enlace de "cancelación de suscripción"). Para excluirse
          de los mensajes de texto, puede utilizar los medios de exclusión
          descritos en el mensaje de texto (por ejemplo, respondiendo "DETENER"
          o "DETENER TODO" al número que se le proporciona en el mensaje de
          texto. Información de la cuenta. Puede acceder a la información de su
          cuenta o actualizar la accediendo a ella. Exclusión de la publicidad
          basada en intereses.
        </p>

        <h3>5. SEGURIDAD</h3>

        <p>
          Hacemos esfuerzos razonables para proteger su información mediante el
          uso de salvaguardas físicas y electrónicas diseñadas para mejorar la
          seguridad de la información que mantenemos. No obstante, dado que
          ninguna transmisión o almacenamiento electrónico de información puede
          ser totalmente seguro, no podemos ofrecer garantías en cuanto a la
          seguridad o la privacidad de su información.
        </p>

        <h3>6. PRIVACIDAD DE LOS NIÑOS</h3>
        <p>
          Los Servicios están dirigidos a un público general, por lo que
          cualquier cuenta o contenido dentro de la aplicación es
          responsabilidad de su o sus creadores, si un menor de edad cuenta con
          acceso a dicho contenido es responsabilidad de sus padres y/o tutores
          en turno.{" "}
        </p>

        <h3>7.TERCEROS</h3>
        <p>
          Nuestros Servicios pueden contener enlaces a otros sitios web,
          productos o servicios que no son de nuestra propiedad ni gestionados
          por nosotros. No somos responsables de las prácticas de privacidad de
          estos terceros. Tenga en cuenta que este Aviso de Privacidad no se
          aplica a sus actividades en estos servicios de terceros ni a la
          información que revele a estos terceros. Le recomendamos que lea sus
          políticas de privacidad antes de facilitarles información.{" "}
        </p>

        <h3>8. JURISDICCIÓN Y TRANSFERENCIA TRANSFRONTERIZA</h3>
        <p>
          Su Información Personal puede ser almacenada y procesada en cualquier
          país en el que tengamos instalaciones o en el que contratemos a
          Proveedores de Servicios, y al utilizar los Servicios usted entiende
          que su información será transferida a países fuera de su país de
          residencia, incluyendo Estados Unidos, México y cualquier otro país en
          el que nosotros o nuestros Proveedores de Servicios operen, que pueden
          tener normas de protección de datos diferentes a las de su país. En
          determinadas circunstancias, los tribunales, las fuerzas del orden,
          los organismos reguladores o las autoridades de seguridad de esos
          otros países pueden tener derecho a acceder a su Información Personal.{" "}
        </p>

        <h3>9. INFORMACIÓN DE CONTACTO</h3>
        <p>
          Siens Software es la empresa responsable de la recopilación, el uso y
          la divulgación de sus Datos Personales en virtud del presente Aviso de
          Privacidad. Si tiene alguna pregunta sobre este Aviso de Privacidad,
          póngase en contacto con nosotros en siens.software.comments@gmail.com.
          Dado que las comunicaciones por correo electrónico no siempre son
          seguras, le rogamos que no incluya información sobre tarjetas de
          crédito u otros datos sensibles en los correos electrónicos que nos
          envíe. Si tiene alguna queja sobre la forma en que tratamos su
          Información Personal, puede presentarla a la autoridad de protección
          de datos pertinente del país en el que reside.
        </p>
      </div>

      {/* Footer */}
      <footer className={styles.footer}>
        <div className={styles.footer_content}>
          <p>{t("contact")}</p>
          <p>siens.software.comments@gmail.com</p>
        </div>
        <div className={styles.footer_links}>
          <a href="/terms">{t("terms")}</a>
          <a href="/policy">{t("policy")}</a>
          <p className={styles.org}>Sīəns &copy;</p>
        </div>
      </footer>
    </div>
  );
};

export default Policy;
