import React, { useEffect } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";

const QRScanner = ({ onSuccess, isActive }) => {
  useEffect(() => {
    const scanner = new Html5QrcodeScanner(
      "scanner", // div ID where is show
      {
        qrbox: 250, // box size
        fps: 1, // frames per second
      }
    );
    scanner.render(onSuccess);
    return () => scanner.clear();
  }, [onSuccess]);

  return <div id="scanner" style={{width: 600, display: isActive ? 'block' : 'none'}} />;
};

export default QRScanner;
