import React, { useState } from "react";
import styles from "./ActiveEvent.module.css";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { functions } from "../../../firebase";
import EventItem from "../../common/eventItem/EventItem";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const ActiveEvent = ({ data }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await deleteEventInvitations();
      await deleteEventImage();
      await deleteEventInfo();
      setOpen(false);
      setLoading(false);
      navigate("/home/myEvents");
    } catch (error) {
      setLoading(false);
      alert(t("generalError"));
    }
  };

  const deleteEventInvitations = async () => {
    try {
      const request = { eventId: data.id };
      const deleteInvitations = httpsCallable(
        functions,
        "deleteInvitationForEvent"
      );
      await deleteInvitations(request);
    } catch (error) {
      setLoading(false);
      alert(t("generalError"));
      return;
    }
  };

  const deleteEventImage = async () => {
    try {
      const request = { id: data.id };
      const deletePicture = httpsCallable(functions, "deleteEventImage");
      await deletePicture(request);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteEventInfo = async () => {
    try {
      const request = { id: data.id };
      const deleteEvent = httpsCallable(functions, "deleteEvent");
      await deleteEvent(request);
    } catch (error) {
      setLoading(false);
      alert(t("generalError"));
      return;
    }
  };

  return (
    <div className={styles.content}>
      <h1>{t("activeEventTitle")}</h1>
      <div className={styles.buttons_content}>
        {/* Action buttons */}
        <div className={styles.buttons_group}>
          <button
            class="solid_button"
            onClick={() => navigate("/home/searchGuest")}
          >
            {t("guestsTitle")}
          </button>
          <button
            class="solid_button"
            onClick={() => navigate("/home/codeScanner")}
          >
            {t("scanInvitation")}
          </button>
        </div>

        {/* Action buttons */}
        <div className={styles.buttons_group}>
          <button class="border_button" onClick={() => setOpen(true)}>
            {t("finishEvent")}
          </button>
        </div>
      </div>

      <div className={styles.border_content}>
        <EventItem item={data} />
      </div>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{t("appName")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("endingModalDescription")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading && <label>{t("endingEvent")}</label>}
          {!loading && (
            <>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                {t("cancel")}
              </Button>
              <Button onClick={handleCancel}>{t("finishEvent")}</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActiveEvent;
