import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import styles from "./Autocomplete.module.css";
import { useTranslation } from "react-i18next";

const PlacesAutocomplete = ({ setPlace, setLocation, updateAddress }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { t, i18n } = useTranslation();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    const words = address.split(",");
    const name = words[0];
    const fullAddress = results[0].formatted_address;
    updateAddress({ name, fullAddress });
    setLocation({
      name: name,
      placeID: results[0].place_id,
      address: address,
      latitude: lat,
      longitude: lng,
    });
  };

  const updateInputText = (text) => {
    setValue(text);
    setPlace(null);
    setLocation(null);
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => updateInputText(e.target.value)}
        disabled={!ready}
        placeholder={t("searchLocationPlaceholder")}
      />

      <ComboboxPopover>
        <ComboboxList className={styles.combo_box}>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <div className={styles.combo_item}>
                <img src="../../img/pin_icon.png" />
                <ComboboxOption
                  className={styles.combo_option}
                  key={place_id}
                  value={description}
                />
              </div>
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

export default PlacesAutocomplete;
