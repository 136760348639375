import styles from "./GuestsList.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { functions } from "./../../../firebase";
import { httpsCallable } from "firebase/functions";
import SmallGuestItem from "../../common/smallGuest/SmallGuestItem";

const GuestsList = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [guestList, setGuestList] = useState([]);
  const maxStaffNumber = 25;

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setFetching(true);
    const request = { id: data.id };
    const fetchInvitations = httpsCallable(functions, "searchEventInvitations");
    try {
      const result = await fetchInvitations(request);
      const response = result.data.data;
      const jsonString = JSON.stringify(response);
      const json = JSON.parse(jsonString);
      const list = Object.keys(json).map((key) => ({
        id: key,
        eventId: json[key].event_id,
        guestId: json[key].guest_id,
        ownerId: json[key].owner_id,
        status: json[key].status,
        type: json[key].type,
      }));
      const filter = list.filter(
        (invitation) => invitation.status !== "denied"
      );
      setGuestList(list);
      setFetching(false);
    } catch (error) {
      setGuestList([]);
      setFetching(false);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.main_container}>
        <h1>{t("guestsListTitle")}</h1>
        {data.information.unlimited && <h1>{t("unlimitedGuestsTitle")}</h1>}
        {!data.information.unlimited && (
          <p>
            {t("unlimitedReminder", {
              maxGuests: data.information.totalGuests,
              maxStaff: maxStaffNumber,
            })}
          </p>
        )}
        <div className={styles.inner_container}>
          {fetching && <p>{t("loading")}</p>}
          {!fetching && (
            <>
              <div className={styles.half_container}>
                <h2>{t("guestListHeader")}</h2>
                <div className={styles.content_box}>
                  <div className={styles.list_container}>
                  {guestList
                    .filter((item) => item.type !== "staff")
                    .map((item) => (
                      <div className={styles.list_item} key={item.id}>
                        <SmallGuestItem item={item} />
                      </div>
                    ))}
                  </div>
                  
                </div>
              </div>
              <div className={styles.half_container}>
                <h2>{t("staffListHeader")}</h2>
                <div className={styles.content_box}>
                <div className={styles.list_container}>
                {guestList
                    .filter((item) => item.type === "staff")
                    .map((item) => (
                      <div className={styles.list_item} key={item.id}>
                        <SmallGuestItem item={item} />
                      </div>
                    ))}
                </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestsList;
