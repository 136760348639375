import styles from "./Home.module.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { auth, storage, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import { signOut } from "firebase/auth";
import QRCode from "react-qr-code";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const Home = ({ user, setUser }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [code, setCode] = useState("");
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState({
    show: false,
    title: "",
    isCode: false,
  });

  useEffect(() => {
    getImageUrl();
  }, [user]);

  const myCode = () => {
    setCodeModal();
    setCode(user.id);
  };

  const setCodeModal = () => {
    setModal({
      show: true,
      title: "",
      isCode: true,
    });
  };

  const setLogoutModal = () => {
    setModal({
      show: true,
      title: t("appName"),
      isCode: false,
    });
  };

  const handleClose = () => {
    setModal({
      show: false,
      title: "",
      isCode: false,
    });
  };

  const doLogout = async () => {
    if (loading) return;
    setLoading(true);
    await updateSession();
    await signOut(auth);
    sessionStorage.clear("session");
    setUser(null);
    navigate("/");
  };

  const updateSession = async () => {
    if (user.id === undefined) return;
    try {
      const data = { id: user.id, isActive: false };
      const updateSession = httpsCallable(functions, "updateSession");
      await updateSession(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getImageUrl = async () => {
    const path = "user/" + user.id + "/small_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
    } catch (error) {
      setUrl(null);
    }
  };

  return (
    <div className={styles.main}>
      {/* Header */}
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src="../../img/logo.png" alt="Logo" />
          <span>Onix</span>
        </div>

        <div className={styles.header_buttons}>
          <img src="../../img/menu_icon.png" />
          <div className={styles.profile_menu}>
            <div className={styles.profile_menu_info}>
              <h2>
                {user.name} {user.lastName}
              </h2>
              {url !== null && <img src={url} />}
              {url === null && <img src="../../img/account_icon.png" />}
            </div>
            <div className={styles.profile_menu_option}>
              <div className={styles.profile_menu_list}>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    <img src="../../img/public_icon.png" />
                    <span>{t("today")}</span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/home/myEvents");
                    }}
                  >
                    <img src="../../img/event_icon.png" />
                    <span>{t("myEvents")}</span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/home/invitations");
                    }}
                  >
                    <img src="../../img/invitation_icon.png" />
                    <span>{t("invitations")}</span>
                  </li>
                </ul>
              </div>
              <div className={styles.profile_menu_list}>
                <ul>
                  <li onClick={myCode}>
                    <img src="../../img/qr_icon.png" />
                    <span>{t("myCode")}</span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/home/profile");
                    }}
                  >
                    <img src="../../img/profile_icon.png" />
                    <span>{t("editProfile")}</span>
                  </li>
                  <li onClick={setLogoutModal}>
                    <img src="../../img/logout_icon.png" />
                    <span>{t("logout")}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div>
        <Outlet />

        <Dialog open={modal.show} onClose={handleClose}>
          <DialogTitle>{modal.title}</DialogTitle>
          <DialogContent>
            {modal.isCode && (
              <div className={styles.modal_code}>
                {url !== null && <img src={url} />}
                {url === null && <img src="../../img/account_icon.png" />}
                <h2>
                  {user.name} {user.lastName}
                </h2>
                <QRCode value={code} />
              </div>
            )}
            {!modal.isCode && (
              <p>{t("logoutMessage")}</p>
            )}
          </DialogContent>
          <DialogActions>
            {loading && <label>{t("loading")}</label>}
            {!loading && (
              <>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                {!modal.isCode && (
                  <Button onClick={doLogout}>{t("doLogout")}</Button>
                )}
              </>
            )}
          </DialogActions>
        </Dialog>
      </div>
      {/* Footer */}
      <footer className={styles.footer}>
        <div className={styles.footer_links}>
          <p className={styles.org}>Sīəns &copy;</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
