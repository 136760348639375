import styles from "./SmallGuest.module.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";

const SmallGuestItem = ({ item }) => {
  const { t, i18n } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [guest, setGuest] = useState(null);
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    if (item.guestId === undefined) {
      setGuest({
        id: item.id,
        name: item.profile.name,
        lastName: item.profile.last_name,
      });
      getPictureUrl(item.id);
    } else {
      fetchGuestInfo();
    }
  }, []);

  const fetchGuestInfo = async () => {
    setFetching(true);
    const requestData = { id: item.guestId };
    const downloadInfo = httpsCallable(functions, "downloadProfile");
    try {
      const result = await downloadInfo(requestData);
      const jsonString = JSON.stringify(result.data);
      const json = JSON.parse(jsonString);
      setGuest({
        id: json.id,
        name: json.profile.name,
        lastName: json.profile.last_name,
        email: json.profile.email,
        birthDate: json.profile.birth_date,
        gender: json.profile.gender,
      });
      await getPictureUrl(item.guestId);
      setFetching(false);
    } catch (error) {
      setGuest(null);
      setFetching(false);
    }
  };

  const getPictureUrl = async (ownerId) => {
    const path = "user/" + ownerId + "/small_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setPicture(url);
    } catch (error) {
      setPicture(null);
    }
  };

  return (
    <div className={styles.item}>
      {picture == null && <img src="../../img/account_icon.png" />}
      {picture != null && <img src={picture} />}
      {fetching && <p>{t("loading")}</p>}
      {!fetching && guest != null && (
        <label>
          {guest.name} {guest.lastName}
        </label>
      )}
    </div>
  );
};

export default SmallGuestItem;
