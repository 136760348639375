import React, { useState } from "react";
import styles from "./CodeScanner.module.css";
import { useTranslation } from "react-i18next";
import QRScanner from "../../common/QRScanner";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const CodeScanner = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [guest, setGuest] = useState(null);
  const [modal, setModal] = useState({
    show: false,
    input: false,
    title: "",
    message: "",
  });

  const handleClose = () => {
    setModal({
      show: false,
      input: false,
      title: "",
      message: "",
    });
    resetValues();
  };

  const resetValues = () => {
    setUrl(null);
    setInvitation(null);
    setGuest(null);
    setLoading(false);
    setUpdating(false);
  };

  const showErrorModal = (message) => {
    setModal({
      show: true,
      input: true,
      title: t("error"),
      message: message,
    });
  };

  const showGuestModal = () => {
    setModal({
      show: true,
      input: false,
      title: t("guestTitle"),
      message: "",
    });
  };

  const updateInvitation = async () => {
    setUpdating(true);
    try {
      const request = { id: invitation.id, status: "used" };
      const update = httpsCallable(functions, "updateInvitationStatus");
      await update(request);
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  const fetchProfileInfo = async (item) => {
    try {
      const data = { id: item.data.guest_id };
      const downloadProfile = httpsCallable(functions, "downloadProfile");
      const result = await downloadProfile(data);
      setGuest({
        id: result.data.id,
        name: result.data.profile.name,
        last_name: result.data.profile.last_name,
      });
      showGuestModal()
      getImageUrl(result.data.id);
    } catch (error) {
      resetValues();
    }
  };

  const getImageUrl = async (userId) => {
    const path = "user/" + userId + "/small_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
    } catch (error) {
      setUrl(null);
    }
  };

  const onSuccess = async (code) => {
    if (loading) return;
    setLoading(true);
    try {
      const request = { id: code };
      const searchInvitation = httpsCallable(functions, "searchInvitationById");
      const result = await searchInvitation(request);
      if (result.data.data.event_id === data.id) {
        setInvitation({
          id: result.data.id,
          event_id: result.data.data.event_id,
          guest_id: result.data.data.guest_id,
          owner_id: result.data.data.owner_id,
          status: result.data.data.status,
          type: result.data.data.type,
        });
        fetchProfileInfo(result.data);
      } else {
        showErrorModal(t("invalidInvitation"));
      }
    } catch (error) {
      showErrorModal(t("invalidCode"));
    }
  };

  return (
    <div className={styles.scanner_content}>
      <div className={styles.scanner_container}>
        <div>
          <h1>{t("scannerTitle")}</h1>
          <p>{t("scannerDescription")}</p>
        </div>
        <QRScanner onSuccess={onSuccess} isActive={!loading} />
        {loading && (
          <div>
            <h1>{t("searching")}</h1>
            <p>{t("loading")}</p>
          </div>
        )}
      </div>

      <Dialog
        open={modal.show}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>{modal.title}</DialogTitle>
        <DialogContent>
          {modal.input && (
            <div>
              <p>{modal.message}</p>
            </div>
          )}
          {!modal.input && (
            <div className={styles.profile_modal}>
              {url === null && <img src="../../img/account_icon.png" />}
              {url !== null && <img src={url} alt="Imagen" />}
              {guest && (
                <h1>
                  {guest.name} {guest.last_name}
                </h1>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {modal.input && <Button onClick={handleClose}>{t("cancel")}</Button>}
          {!modal.input && (
            <>
              {updating && <label>{t("registering")}</label>}
              {!updating && (
                <Button onClick={updateInvitation}>{t("register")}</Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CodeScanner;
