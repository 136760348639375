import styles from "./SearchGuest.module.css";
import { useTranslation } from "react-i18next";
import { React, useState, useEffect } from "react";
import { functions } from "./../../../firebase";
import { httpsCallable } from "firebase/functions";
import GuestItem from "./../../common/guestItem/GuestItem";
import SmallGuestItem from "../../common/smallGuest/SmallGuestItem";
import { useNavigate } from "react-router-dom";
import Searching from "../../common/searching/Searching";

const SearchGuest = ({ data }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [text, setText] = useState("");
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [guestList, setGuestList] = useState([]);
  const maxGuestNumber = data.information.totalGuests;
  const maxStaffNumber = 25;

  useEffect(() => {
    fetchList();
  }, []);

  const addToList = (item) => {
    setList(list.filter((guest) => guest !== item));
    selected.push(item);
    setSelected(selected);
  };

  const removeSelected = (item) => {
    const filter = selected.filter((guest) => guest !== item);
    setSelected(filter);
  };

  const handleStaffChoice = async () => {
    if (data.information.unlimited) {
      sendInvitation("staff");
    } else {
      const staff = guestList.filter((item) => item.type === "staff");
      let total = staff.length + selected.length;
      if (total > maxStaffNumber) {
        alert(t("maxStaffError", {number: maxGuestNumber}));
      } else {
        sendInvitation("staff");
      }
    }
  };

  const handleInvitationChoice = async () => {
    if (data.information.unlimited) {
      sendInvitation("invitation");
    } else {
      const guests = guestList.filter((item) => item.type !== "staff");
      let total = guests.length + selected.length;
      if (total > maxGuestNumber) {
        alert(t("maxGuestsError", {number: maxGuestNumber}));
      } else {
        sendInvitation("invitation");
      }
    }
  };

  const fetchList = async () => {
    setFetching(true);
    const request = { id: data.id };
    const fetchInvitations = httpsCallable(functions, "searchEventInvitations");
    try {
      const result = await fetchInvitations(request);
      const response = result.data.data;
      const jsonString = JSON.stringify(response);
      const json = JSON.parse(jsonString);
      const list = Object.keys(json).map((key) => ({
        id: key,
        eventId: json[key].event_id,
        guestId: json[key].guest_id,
        ownerId: json[key].owner_id,
        status: json[key].status,
        type: json[key].type,
      }));
      const filter = list.filter(
        (invitation) => invitation.status !== "denied"
      );
      setGuestList(list);
      setFetching(false);
    } catch (error) {
      setGuestList([]);
      setFetching(false);
    }
  };

  const searchText = async () => {
    if (text === "" || text === " " || loading) return;
    setLoading(true);
    const info = { id: data.ownerId, text: text };
    const searchProfileBy = httpsCallable(functions, "searchProfileByText");
    try {
      const result = await searchProfileBy(info);
      var filterList = result.data.data;
      if (filterList.length === 0) {
        setLoading(false);
        return;
      }
      // Crear un conjunto único de IDs a filtrar
      const filteredIds = new Set();
      // Añadir IDs de guestList al conjunto
      guestList.forEach((guest) => filteredIds.add(guest.guestId));
      // Añadir IDs de selected al conjunto
      selected.forEach((guest) => filteredIds.add(guest.id));
      // Filtrar filterList una sola vez usando el conjunto
      filterList = filterList.filter((item) => !filteredIds.has(item.id));
      setList(filterList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const sendInvitation = async (type) => {
    setList([]);
    setLoading(true);
    let list = selected.map((guest) =>
      JSON.stringify({
        eventId: data.id,
        guestId: guest.id,
        ownerId: data.ownerId,
        status: "send",
        type: type,
      })
    );
    const info = { list: list };
    const createInvitations = httpsCallable(functions, "createInvitations");
    try {
      await createInvitations(info);
      setLoading(false);
      navigate("/home/myEvents");
    } catch (error) {
      alert(t("generalError"));
      setLoading(false);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.border_content}>
        {data.information.unlimited && <h1>{t("unlimitedGuestsTitle")}</h1>}
        {!data.information.unlimited && (
          <p>
            {t("unlimitedReminder", {
              maxGuests: data.information.totalGuests,
              maxStaff: maxStaffNumber,
            })}
          </p>
        )}
        {fetching && <p>{t("loading")}</p>}
        {guestList.length > 0 && (
          <div>
            <h1>{t("withInvitationTitle")}</h1>
            <div className={styles.selected_list}>
              {guestList.map((item) => (
                <div className={styles.small_item} key={item.id}>
                  <SmallGuestItem item={item} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {selected.length > 0 && (
        <div className={styles.selected_content}>
          <div className={styles.buttons_content}>
            <h1>{t("selected")}</h1>
            {/* Action buttons */}
            {loading && <p>{t("loading")}</p>}
            {!loading && (
              <div className={styles.button_group}>
                <button
                  class="solid_button"
                  onClick={() => {
                    handleInvitationChoice();
                  }}
                >
                  {t("sendInvitation")}
                </button>
                <button
                  class="border_button"
                  onClick={() => {
                    handleStaffChoice();
                  }}
                >
                  {t("includeAsStaff")}
                </button>
              </div>
            )}
          </div>
          <div className={styles.selected_list}>
            {selected.map((item) => (
              <div className={styles.small_item} key={item.id}>
                <SmallGuestItem item={item} />
                <button
                  class="solid_button"
                  onClick={() => {
                    removeSelected(item);
                  }}
                >
                  {t("remove")}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.border_content}>
        <h1>{t("searchForGuests")}</h1>
        <input
          placeholder={t("searchPlaceholder")}
          type="text"
          name="text"
          required
          onChange={(event) => {
            setText(event.target.value);
            if (event.target.value == "") {
              setList([]);
            }
          }}
        />
        <button class="solid_button" onClick={searchText}>
          {t("search")}
        </button>
      </div>

      <div className={styles.list_container}>
        {loading ? (
          <Searching />
        ) : list.length === 0 ? (
          <div className={styles.empty_list}>
            <h1>{t("noResults")}</h1>
            <p>{t("searchHint")}</p>
            <img src="../../img/mountain.png" />
          </div>
        ) : (
          <div className={styles.guest_list}>
            {list.map((item) => (
              <div className={styles.guest_item} key={item.id}>
                <GuestItem item={item} />
                <button
                  class="solid_button"
                  onClick={() => {
                    addToList(item);
                  }}
                >
                  {t("add")}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchGuest;
