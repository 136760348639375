import React, { useState } from "react";
import styles from "./SignUp.module.css";
import { useTranslation } from "react-i18next";
import { auth, functions, storage } from "../../../firebase";
import { ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Message from "../../common/Message";
import imageCompression from "browser-image-compression";

const SignUp = ({ setUser }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [pass, setPass] = useState("");
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [profile, setProfile] = useState({
    name: "",
    lastName: "",
    email: "",
    birthDate: new Date(),
    gender: "unknown",
  });

  const [visibility, setVisibility] = useState({
    visible: false,
    class: "visibility_off",
    type: "password",
  });

  const [modal, setModal] = useState({
    show: false,
    title: "",
    message: "",
  });

  const handleClose = () => {
    setModal({
      show: false,
      title: "",
      message: "",
    });
    setLoading(false);
  };

  const setErrorModal = (message) => {
    setModal({
      show: true,
      title: t("error"),
      message: message,
    });
  };

  const handleFile = (event) => {
    const selected = event.target.files[0];
    setUrl(URL.createObjectURL(selected));
    setFile(selected);
  };

  const navigateHome = (userId) => {
    setUser({
      id: userId,
      name: profile.name,
      lastName: profile.lastName,
      email: profile.email,
      birthDate: profile.birthDate,
      gender: profile.gender,
    });
    navigate("/home");
  };

  const uploadPicture = async (userId) => {
    // Create path reference
    const path = "user/" + userId + "/profile_photo/profile_photo.jpg";
    const reference = ref(storage, path);
    try {
      await uploadBytes(reference, file);
      await uploadSmallPicture(userId);
      navigateHome(userId);
    } catch (error) {
      navigateHome(userId);
    }
  };

  const uploadSmallPicture = async (userId) => {
    // Create path reference
    const path = "user/" + userId + "/small_photo/profile_photo.jpg";
    const reference = ref(storage, path);
    const options = {
      maxSizeMB: 1, // set a max file size in megabytes after compression.
      maxWidthOrHeight: 1920, // set a max dimension (either width or height) for the output image
      useWebWorker: true, // use a web worker for the compression task, offloading the main thread.
    };
    try {
      const compressedFile = await imageCompression(file, options);
      await uploadBytes(reference, compressedFile);
    } catch (error) {
      // TODO: Handle correctly
    }
  };

  const toggleVisibility = () => {
    var button;
    var type;
    if (visibility.visible) {
      button = "visibility_off";
      type = "password";
    } else {
      button = "visibility_on";
      type = "text";
    }

    setVisibility({
      visible: !visibility.visible,
      class: button,
      type: type,
    });
  };

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    if (loading) return;
    const inputResult = validateInput();
    if (!inputResult.valid) {
      setErrorModal(inputResult.message);
      return;
    }
    const result = isValidPasswordFormat();
    if (result.valid) {
      createUser();
    } else {
      setErrorModal(result.message);
    }
  };

  const validateInput = () => {
    if (!profile.name.trim() || !profile.lastName.trim()) {
      return { valid: false, message: t("emptyNameOrLast") };
    } else if (!/^\S+@\S+\.\S+$/.test(profile.email)) {
      return { valid: false, message: t("emailFormatError") };
    } else if (!isChecked) {
      return { valid: false, message: t("acceptTerms") };
    }
    return { valid: true };
  };

  const isValidPasswordFormat = () => {
    // Verificar que la longitud de la contraseña sea de al menos 6 caracteres
    if (pass.length < 6) {
      return {
        valid: false,
        message: t("passwordInvalid1"),
      };
    }

    // Verificar que la contraseña contenga al menos una letra mayúscula
    if (!/[A-Z]/.test(pass)) {
      return {
        valid: false,
        message: t("passwordInvalid2"),
      };
    }

    // Verificar que la contraseña contenga al menos un número
    if (!/\d/.test(pass)) {
      return {
        valid: false,
        message: t("passwordInvalid3"),
      };
    }

    return { valid: true, message: "" };
  };

  const mapRequestData = () => {
    const needUpdate = file === null;
    const dateString = format(new Date(profile.birthDate), "dd MM yyyy");
    const jsonProfile = JSON.stringify({
      birthDate: dateString,
      email: profile.email,
      gender: profile.gender,
      isSessionActive: true,
      lastName: profile.lastName,
      name: profile.name,
      needUpdate: needUpdate,
    });
    const data = {
      profile: jsonProfile,
      password: pass,
      birthDate: dateString,
      needUpdate: needUpdate,
    };
    return data;
  };

  const createUser = async () => {
    setLoading(true);
    try {
      const createUserWith = httpsCallable(functions, "createUser");
      const data = mapRequestData();
      await createUserWith(data);
      signIn();
    } catch (error) {
      handleCreateUserError(error);
    }
  };

  const handleCreateUserError = (error) => {
    setLoading(false);
    const message = error.message;
    let errorMessage = t("generalError");
    if (message.includes("email address is improperly formatted")) {
      errorMessage = t("emailFormatError");
    } else if (message.includes("The password must be a string with at least 6 characters")) {
      errorMessage = t("passwordInvalid1");
    } else if (message.includes("already in use by another account")) {
      errorMessage = t("emailInUse");
    }
    setErrorModal(errorMessage);
  };

  const signIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        profile.email,
        pass
      );
      // Sendig email verification
      await sendEmailVerification(userCredential.user);
      const user = userCredential.user;
      if (file === null) {
        navigateHome(user.uid);
      } else {
        uploadPicture(user.uid);
      }
    } catch (error) {
      const message = t("generalError");
      setErrorModal(message);
    }
  };

  return (
    <div className={styles.main}>
      {/* Header */}
      <header className={styles.header}>
        <a href="/" className={styles.logo}>
          <img src="../../img/logo.png" alt="Logo" />
          <span>Onix</span>
        </a>

        <div className={styles.header_buttons}>
          <button
            class="border_button"
            onClick={() => {
              navigate("/signUp");
            }}
          >
            {t("signUp")}
          </button>
          <button
            class="solid_button"
            onClick={() => {
              navigate("/signIn");
            }}
          >
            {t("signIn")}
          </button>
        </div>
      </header>

      <div>
        <div className={styles.content}>
          <h1>{t("createProfile")}</h1>

          <div className={styles.inner_content}>
            <div className={styles.left_content}>
              {url === null && <img src="../../img/account_icon.png" />}
              {url !== null && <img src={url} />}
              <input type="file" onChange={handleFile} />
            </div>

            <div className={styles.right_content}>
              <input
                placeholder={t("name")}
                type="text"
                name="name"
                required
                onChange={(event) => {
                  setProfile({
                    name: event.target.value,
                    lastName: profile.lastName,
                    email: profile.email,
                    birthDate: profile.birthDate,
                    gender: profile.gender,
                  });
                }}
              />

              <input
                placeholder={t("lastname")}
                type="text"
                name="lastname"
                required
                onChange={(event) => {
                  setProfile({
                    name: profile.name,
                    lastName: event.target.value,
                    email: profile.email,
                    birthDate: profile.birthDate,
                    gender: profile.gender,
                  });
                }}
              />

              <input
                placeholder={t("email")}
                type="email"
                name="email"
                required
                onChange={(event) => {
                  setProfile({
                    name: profile.name,
                    lastName: profile.lastName,
                    email: event.target.value,
                    birthDate: profile.birthDate,
                    gender: profile.gender,
                  });
                }}
              />

              <div class="visibility-input">
                <input
                  placeholder={t("password")}
                  type={visibility.type}
                  name="pass"
                  required
                  onChange={(event) => {
                    setPass(event.target.value);
                  }}
                />
                <button class={visibility.class} onClick={toggleVisibility} />
              </div>

              <div className={styles.check_box}>
                <button
                  onClick={() => {
                    setIsChecked(!isChecked);
                  }}
                >
                  {!isChecked && (
                    <img src="../../img/check_box_icon.png" alt="Imagen" />
                  )}
                  {isChecked && (
                    <img src="../../img/check_box_fill_icon.png" alt="Imagen" />
                  )}
                </button>
                <p>
                  {t("legal1")}
                  <button
                    onClick={() => {
                      navigate("/terms");
                    }}
                  >
                    {t("termsAndConditions")}
                  </button>
                  {t("legal2")}
                  <button
                    onClick={() => {
                      navigate("/privacyPolicy");
                    }}
                  >
                    {t("privacyPolicies")}
                  </button>
                  {t("legal3")}
                </p>
              </div>

              <p>{t("birthDayOptional")}</p>
              <input
                placeholder={t("birthDayOptional")}
                type="date"
                name="birthday"
                onChange={(event) => {
                  setProfile({
                    name: profile.name,
                    lastName: profile.lastName,
                    email: profile.email,
                    birthDate: event.target.value,
                    gender: profile.gender,
                  });
                }}
              />

              <p>{t("genderOptional")}</p>
              <select
                id="genders"
                className={styles.gender_select}
                onChange={(event) => {
                  setProfile({
                    name: profile.name,
                    lastName: profile.lastName,
                    email: profile.email,
                    birthDate: profile.birthDate,
                    gender: event.target.value,
                  });
                }}
              >
                <optgroup>
                  <option value="unknown">-</option>
                  <option value="female">{t("woman")}</option>
                  <option value="male">{t("man")}</option>
                  <option value="other">{t("other")}</option>
                </optgroup>
              </select>

              <div className={styles.password_hint}>
                <p>{t("passwordHint")}</p>
                <ul>
                  <li>{t("hint1")}</li>
                  <li>{t("hint2")}</li>
                  <li>{t("hint3")}</li>
                </ul>
              </div>

              <button class="solid_button" onClick={handleSubmit}>
                {t("createProfile")}
              </button>

              {loading && <Message message={t("creating")} />}
            </div>

            <Dialog open={modal.show} onClose={handleClose}>
              <DialogTitle>{modal.title}</DialogTitle>
              <DialogContent>
                <DialogContentText>{modal.message}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className={styles.footer}>
        <div className={styles.footer_content}>
          <p>{t("contact")}</p>
          <p>siens.software.comments@gmail.com</p>
        </div>
        <div className={styles.footer_links}>
          <a href="/terms">{t("terms")}</a>
          <a href="/policy">{t("policy")}</a>
          <p className={styles.org}>Sīəns &copy;</p>
        </div>
      </footer>
    </div>
  );
};

export default SignUp;
