import { format } from "date-fns";
import styles from "./EventItem.module.css";
import { storage } from "./../../../firebase";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";

const EventItem = (item) => {
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    getImageUrl();
  }, []);

  const getImageUrl = async () => {
    const path = "event/" + item.item.id + "/event_image/event_image.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
    } catch (error) {
      setUrl(null);
    }
  };

  const dateString = () => {
    //This helps to parse the date in Safari
    const dateString = item.item.information.date.replace("-", "/");
    const newDate = new Date(dateString);
    const value = format(newDate, "dd MMM yyyy");
    return value;
  };

  const timeString = () => {
    const dateString = item.item.information.time.replace("-", "/");
    const newDate = new Date(dateString);
    const value = format(newDate, "HH:mm a");
    return value;
  };

  return (
    <div className={styles.content}>
      <div className={styles.item_image}>
        {url === null && <img />}
        {url !== null && <img src={url} />}
      </div>

      <div className={styles.info_container}>
        <h1>{item.item.description.name}</h1>
        <h3>{t("date")} : {dateString()}</h3>
        <h3>{t("time")} : {timeString()}</h3>
        {item.item.description.description !== "" && (
          <textarea name="description" rows="10" readOnly>
            {item.item.description.description}
          </textarea>
        )}

        <div className={styles.location_container}>
          <div className={styles.location_content}>
            <h2>{t("modalLocationTitle")}</h2>
            <div className={styles.map}>
              <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                libraries={["places"]}
              >
                <Map
                  mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                  zoom={14}
                  center={{
                    lat: parseFloat(item.item.location.coordinate.lat),
                    lng: parseFloat(item.item.location.coordinate.lng),
                  }}
                >
                  <AdvancedMarker
                    position={{
                      lat: parseFloat(item.item.location.coordinate.lat),
                      lng: parseFloat(item.item.location.coordinate.lng),
                    }}
                  >
                    <Pin
                      background={process.env.REACT_APP_PIN_BACKGROUND}
                      glyphColor={process.env.REACT_APP_PIN_GLYPH_COLOR}
                      borderColor={process.env.REACT_APP_PIN_BORDER_COLOR}
                    />
                  </AdvancedMarker>
                </Map>
              </APIProvider>
            </div>
            <h2>{item.item.location.name}</h2>
            <p>{item.item.location.address}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;
