import CryptoJS from 'crypto-js';
import { v4 as uuid } from "uuid";
import React, { useEffect } from "react";
import styles from "./Payment.module.css";
import { useTranslation } from "react-i18next";
import { functions } from "./../../../firebase";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";

const PaymentSuccess = ({ setUser }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const encryptedSession = sessionStorage.getItem("data");
    const sessionBytes = CryptoJS.AES.decrypt(encryptedSession, process.env.REACT_APP_ENCRYPTION_SECRET_KEY);
    const sessionString = sessionBytes.toString(CryptoJS.enc.Utf8);
    const user = JSON.parse(sessionString);
    setUser(user);

    const encryptedData = sessionStorage.getItem("info");
    const dataBytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_SECRET_KEY);
    const dataString = dataBytes.toString(CryptoJS.enc.Utf8);
    const data = JSON.parse(dataString);

    if (data.id === null || data.id === "") {
      createEvent(data);
    } else {
      updateEventInfo(data);
    }
  }, []);

  const createEvent = async (data) => {
    const request = mapData(data);
    const createEventRequest = httpsCallable(functions, "createEvent");
    try {
      const result = await createEventRequest(request);
      if (result.data.success) {
        sessionStorage.clear("data");
      } else {
        alert(t("generalError"));
      }
    } catch (error) {
      alert(t("generalError"));
    }
  };

  const updateEventInfo = async (data) => {
    const request = mapData(data);
    const updateEvent = httpsCallable(functions, "updateEvent");
    try {
      const result = await updateEvent(request);
      if (result.data.success) {
        sessionStorage.clear("data");
      } else {
        alert(t("generalError"));
      }
    } catch (error) {
      alert(t("generalError"));
    }
  };

  const mapData = (data) => {
    const jsonData = JSON.stringify({
      id: data.id === null || data.id === "" ? uuid() : data.id,
      owner_id: data.owner_id,
      transactionId: uuid(),
      location: data.location,
      information: data.information,
      description: data.description,
    });

    const { latitude, longitude } =
      data.id === null || data.id === ""
        ? {
            latitude: data.location.latitude.toString(),
            longitude: data.location.longitude.toString(),
          }
        : {
            latitude: data.location.coordinate.lat,
            longitude: data.location.coordinate.lng,
          };

    const dataRequest = {
      eventData: jsonData,
      date: data.information.date,
      time: data.information.time,
      latitude: latitude,
      longitude: longitude,
      customImage: false, // TODO: update this
    };
    return dataRequest;
  };

  return (
    <div className={styles.success_content}>
      <div className={styles.payment_success}>
        <h1>{t("successPaymentTitle")}</h1>
        <img src="../../img/check_mark.png" />
        <p>{t("successPaymentMessage")}</p>
        <button
          class="solid_button"
          onClick={() => {
            navigate("/home/myEvents");
          }}
        >
          {t("myEvents")}
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
