import React, { useState } from "react";
import styles from "./SignIn.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { httpsCallable } from "firebase/functions";
import { sendPasswordResetEmail } from "firebase/auth";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Message from "../../common/Message";
import { auth, functions /*googleProvider*/ } from "../../../firebase";
import { signInWithEmailAndPassword /*signInWithPopup*/ } from "firebase/auth";

const SignIn = ({ setUser }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [secondEmail, setSecondEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [visibility, setVisibility] = useState({
    visible: false,
    class: "visibility_off",
    type: "password",
  });

  const [modal, setModal] = useState({
    show: false,
    input: false,
    title: "",
    message: "",
  });

  /*
  const signInWithGoogle = async (event) => {
    // <button onClick={signInWithGoogle}>Iniciar sesión con Google</button>
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;
      downloadProfile(user.uid);
    } catch (error) {
      console.error(error);
    }
  };
  */

  const toggleVisibility = () => {
    var button;
    var type;
    if (visibility.visible) {
      button = "visibility_on";
      type = "password";
    } else {
      button = "visibility_off";
      type = "text";
    }

    setVisibility({
      visible: !visibility.visible,
      class: button,
      type: type,
    });
  };

  const handleClose = () => {
    setModal({
      show: false,
      input: false,
      title: "",
      message: "",
    });
    setSecondEmail("");
    setLoading(false);
  };

  const sendForgotPass = async () => {
    try {
      await sendPasswordResetEmail(auth, secondEmail);
      handleClose();
    } catch (error) {
      // alert instead of dialog
      var message = t("generalError");
      switch (error.code) {
        case "auth/invalid-email":
          message = t("emailFormatError");
          break;
        case "auth/missing-email":
          message = t("emptyEmail");
          break;
      }
      alert(message);
    }
  };

  const setResetPassModal = () => {
    setModal({
      show: true,
      input: true,
      title: t("updatePassword"),
      message: t("updatePasswordMessage"),
    });
  };

  const setErrorModal = (message) => {
    setModal({
      show: true,
      input: false,
      title: t("error"),
      message: message,
    });
  };

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    if (email.length === 0 || pass.length === 0) {
      return;
    }
    if (!loading) {
      setLoading(true);
      // sign in
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          pass
        );
        const user = userCredential.user;
        downloadProfile(user.uid);
      } catch (error) {
        var message = t("generalError");
        switch (error.code) {
          case "auth/invalid-email":
            message = t("emailFormatError");
            break;
          case "auth/invalid-login-credentials":
            message = t("invalidCredentials");
            break;
          case "auth/network-request-failed":
            message = t("connectionError");
            break;
        }
        setErrorModal(message);
        //TODO:
        //Access to this account has been temporarily disabled due to many failed login attempts.
        //You can immediately restore it by resetting your password or you can try again later.
        //(auth/too-many-requests).
      }
    }
  };

  const downloadProfile = async (id) => {
    try {
      const data = { id: id };
      const downloadProfile = httpsCallable(functions, "downloadProfile");
      const result = await downloadProfile(data);
      updateSession({
        id: result.data.id,
        isActive: true,
        profile: result.data.profile,
      });
    } catch (error) {
      const message = t("generalError");
      setErrorModal(message);
    }
  };

  const updateSession = async (props) => {
    try {
      const data = { id: props.id, isActive: props.isActive };
      const updateSession = httpsCallable(functions, "updateSession");
      await updateSession(data);
      setUser({
        id: props.id,
        name: props.profile.name,
        lastName: props.profile.last_name,
        email: props.profile.email,
        birthDate: props.profile.birth_date,
        gender: props.profile.gender,
      });
      navigate("/home");
    } catch (error) {
      const message = t("generalError");
      setErrorModal(message);
    }
  };

  return (
    <div className={styles.main}>
      {/* Header */}
      <header className={styles.header}>
        <a href="/" className={styles.logo}>
          <img src="../../img/logo.png" alt="Logo" />
          <span>Onix</span>
        </a>

        <div className={styles.header_buttons}>
          <button
            class="border_button"
            onClick={() => {
              navigate("/signUp");
            }}
          >
            {t("signUp")}
          </button>
          <button
            class="solid_button"
            onClick={() => {
              navigate("/signIn");
            }}
          >
            {t("signIn")}
          </button>
        </div>
      </header>

      <div>
        <div className={styles.content}>
          <h1>{t("signIn")}</h1>
          <div class="visibility-input">
            <input
              placeholder={t("email")}
              type="email"
              name="email"
              required
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>

          <div class="visibility-input">
            <input
              placeholder={t("password")}
              type={visibility.type}
              name="pass"
              required
              onChange={(event) => {
                setPass(event.target.value);
              }}
            />
            <button class={visibility.class} onClick={toggleVisibility} />
          </div>

          <button class="solid_button" onClick={handleSubmit}>
            {t("signIn")}
          </button>

          <button class="label_button" onClick={setResetPassModal}>
            {t("forgotPassword")}
          </button>

          {loading && <Message message={t("logginIn")} />}
        </div>

        <Dialog open={modal.show} onClose={handleClose}>
          <DialogTitle>{modal.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{modal.message}</DialogContentText>
            {modal.input && (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("email")}
                type="email"
                fullWidth
                variant="standard"
                onChange={(event) => {
                  setSecondEmail(event.target.value);
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("cancel")}</Button>
            {modal.input && (
              <Button onClick={sendForgotPass}>{t("send")}</Button>
            )}
          </DialogActions>
        </Dialog>
      </div>

      {/* Footer */}
      <footer className={styles.footer}>
        <div className={styles.footer_content}>
          <p>{t("contact")}</p>
          <p>siens.software.comments@gmail.com</p>
        </div>
        <div className={styles.footer_links}>
          <a href="/terms">{t("terms")}</a>
          <a href="/policy">{t("policy")}</a>
          <p className={styles.org}>Sīəns &copy;</p>
        </div>
      </footer>
    </div>
  );
};

export default SignIn;
