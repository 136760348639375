import styles from "./Payment.module.css";
import { functions } from "./../../../firebase";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { httpsCallable } from "firebase/functions";
import React, { useCallback, useEffect, useState } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

const Payment = () => {
  return (
    <div className={styles.content}>
      <CheckoutForm />
    </div>
  );
};

export default Payment;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CheckoutForm = () => {
  const { t, i18n } = useTranslation();
  const [secret, setSecret] = useState("");

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    try {
      const data = { price: process.env.REACT_APP_STRIPE_PRICE };
      const stripeCheckout = httpsCallable(functions, "stripeCheckout");
      const result = await stripeCheckout(data);
      setSecret(result.data.clientSecret);
    } catch (error) {
      alert(t("generalError"));
    }
  }, []);

  return (
    <div id="checkout">
      {secret === "" && (
        <div className={styles.loader}>
          <h1>{t("loading")}</h1>
          <p>{t("fetchingStripeInfo")}</p>
        </div>
      )}
      {secret !== "" && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret: secret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};
