import styles from "./MyEvents.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";
import { useEffect, useState, useReducer } from "react";
import EventItem from "../../common/eventItem/EventItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Searching from "../../common/searching/Searching";

const MyEvents = ({ user, setEventData }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [list, setList] = useState([]);
  const [sort, setSort] = useState([]);
  const navigationPath = "/home/eventLocation";
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [fetching, setFetching] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [open, setOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    fetchProfileEventList();

    const intervalId = setInterval(() => {
      setSort((prevSort) =>
        prevSort.map((item) => ({
          ...item,
          isToday: checkDate(item), // Add isToday property to item
        }))
      );
    }, 1000);

    return () => clearInterval(intervalId); // Clear the interval when the component is disassembled
  }, []);

  const segmentSelected = (type) => {
    setIsPublic(type === "public");
    setSort(
      list.filter(
        (item) => item.information.type === type && item.status === "created"
      )
    );
  };

  const cancelDialog = (item) => {
    setSelected(item);
    setOpen(true);
  };

  const sendInvitation = (data) => {
    setEventData(data);
    navigate("/home/searchGuest");
  };

  const editData = (item) => {
    setEventData(item);
    navigate("/home/editEvent");
  };

  const checkDate = (item) => {
    const currentDate = new Date();
    const date = new Date(item.information.date);
    const time = new Date(item.information.time);

    const isOnDate =
      currentDate.getFullYear() === date.getFullYear() &&
      currentDate.getMonth() === date.getMonth() &&
      currentDate.getDate() === date.getDate();

    const isOnTime =
      currentDate.getHours() >= time.getHours() &&
      currentDate.getMinutes() >= time.getMinutes();

    return isOnDate && isOnTime;
  };

  const handleCancel = async () => {
    setCancel(true);
    try {
      await deleteEventInvitations();
      await deleteEventImage();
      await deleteEventInfo();
      setOpen(false);
      setCancel(false);
      navigate("/home");
    } catch (error) {
      setCancel(false);
      alert(t("generalError"));
    }
  };

  const deleteEventImage = async () => {
    try {
      const data = { id: selected.id };
      const deletePicture = httpsCallable(functions, "deleteEventImage");
      await deletePicture(data);
    } catch (error) {
      setCancel(false);
    }
  };

  const deleteEventInvitations = async () => {
    const data = { eventId: selected.id };
    const deleteInvitations = httpsCallable(
      functions,
      "deleteInvitationForEvent"
    );
    try {
      await deleteInvitations(data);
    } catch (error) {
      setCancel(false);
      alert(t("generalError"));
      return;
    }
  };

  const deleteEventInfo = async () => {
    const data = { id: selected.id };
    const deleteEvent = httpsCallable(functions, "deleteEvent");
    try {
      await deleteEvent(data);
    } catch (error) {
      setCancel(false);
      alert(t("generalError"));
      return;
    }
  };

  const createInvitation = async (item) => {
    const data = { event_id: item.id, owner_id: item.ownerId };
    const createOwnerInvitation = httpsCallable(
      functions,
      "createOwnerInvitation"
    );
    try {
      await createOwnerInvitation(data);
    } catch (error) {
      setCancel(false);
      alert(t("generalError"));
    }
  };

  const beginEvent = async (item) => {
    if (hasStarted) {
      alert(t("activeEventError"));
    } else {
      setLoading(true);
      const data = { id: item.id, status: "begin" };
      const updateStatus = httpsCallable(functions, "updateEventStatus");
      try {
        await createInvitation(item);
        await updateStatus(data);
        fetchProfileEventList();
        setLoading(false);
        navigate("/home/activeEvent");
      } catch (error) {
        setLoading(false);
        alert(t("generalError"));
      }
    }
  };

  const fetchProfileEventList = async () => {
    setFetching(true);
    const data = { id: user.id };
    const functionList = httpsCallable(functions, "profileEventList");
    try {
      const result = await functionList(data);
      const jsonString = JSON.stringify(result.data.data);
      const json = JSON.parse(jsonString);
      const list = Object.values(json).map((item) => mapItem(item));
      setFetching(false);
      setList(list);
      setHasStarted(list.some((item) => item.status === "begin"));
      setSort(
        list.filter(
          (item) =>
            item.information.type === "public" && item.status === "created"
        )
      );
      forceUpdate();
    } catch {
      setFetching(false);
      setList([]);
      forceUpdate();
    }
  };

  const mapItem = (item) => {
    return {
      id: item.id,
      ownerId: item.owner_id,
      transactionId: item.transaction_id,
      status: item.status,
      location: {
        name: item.place_name,
        placeID: item.place_id,
        address: item.address,
        coordinate: { lat: item.latitude, lng: item.longitude },
      },
      information: {
        type: item.type,
        date: item.date,
        time: item.time,
        totalGuests: item.total_guests,
        unlimited: item.unlimited,
      },
      description: {
        name: item.name,
        description: item.description,
        cost: item.cost,
      },
    };
  };

  return (
    <div className={styles.content}>
      <h1>{t("myEventTitle")}</h1>
      <div className={styles.buttons}>
        {/* Action buttons */}
        <div className={styles.buttons_group}>
          <button
            class="solid_button"
            onClick={() => segmentSelected("public")}
          >
            {t("public")}
          </button>
          <button
            class="solid_button"
            onClick={() => segmentSelected("private")}
          >
            {t("private")}
          </button>
        </div>

        {/* Action buttons */}
        <div className={styles.buttons_group}>
          <button
            class="border_button"
            onClick={() => {
              navigate(navigationPath);
            }}
          >
            {t("createEvent")}
          </button>
          {hasStarted && (
            <button
              class="border_button"
              onClick={() => {
                const activeItem = list.find((item) => item.status === "begin");
                setEventData(activeItem);
                navigate("/home/activeEvent");
              }}
            >
              {t("activeEvent")}
            </button>
          )}
        </div>
      </div>

      <div>
        <div className={styles.list}>
          {fetching && <Searching />}
          {!fetching && sort.length === 0 && (
            <div className={styles.empty_wrapper}>
              <h1>{t("emptyList")}</h1>
              {isPublic && <p>{t("emptyPublicDescription")}</p>}
              {!isPublic && <p>{t("emptyPrivateDescription")}</p>}
              <img src="../../img/mountain.png" />
            </div>
          )}

          {!fetching &&
            sort.length > 0 &&
            sort.map((item) => (
              <div className={styles.event_item} key={item.id}>
                <EventItem item={item} />
                {/*<!-- Actions -->*/}
                <div className={styles.item_buttons}>
                  {loading && <p>{t("loading")}</p>}
                  {!loading && (
                    <>
                      {item.isToday && (
                        <button
                          class="solid_button"
                          onClick={() => beginEvent(item)}
                        >
                          {t("startEvent")}
                        </button>
                      )}
                      <button
                        class="solid_button"
                        onClick={() => editData(item)}
                      >
                        {t("edit")}
                      </button>
                      <button
                        class="solid_button"
                        onClick={() => sendInvitation(item)}
                      >
                        {t("invite")}
                      </button>
                      <button
                        class="border_button"
                        onClick={() => cancelDialog(item)}
                      >
                        {t("cancel")}
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{t("cancelEventTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("cancelEventDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancel && <label>{t("cancelling")}</label>}
          {!cancel && (
            <>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                {t("no")}
              </Button>
              <Button onClick={handleCancel}>{t("doCancel")}</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyEvents;
