import styles from "./IndexError.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const IndexError = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t("noSuchPageTitle")}</h1>
      <p>{t("pageErrorDescription")}</p>
      <button
        class="border_button"
        onClick={() => {
          navigate("/");
        }}
      >
        {t("goIndex")}
      </button>

      <img className={styles.image} src="../../img/error.png" />
    </div>
  );
};

export default IndexError;
