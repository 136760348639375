import React from "react";
import styles from "./Searching.module.css";
import { useTranslation } from "react-i18next";

const Searching = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.searching}>
      <h1>{t("searching")}</h1>
      <p>{t("loading")}</p>
      <img src="../../img/lupa.png" />
    </div>
  );
};

export default Searching;
