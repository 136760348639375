import CryptoJS from 'crypto-js';
import styles from "./EditEvent.module.css";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Message from "./../../common/Message";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "./../../../firebase";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";
import PlacesAutocomplete from "../../common/Autocomplete/PlacesAutocomplete";
import PlaceClick from "./../../common/PlaceClick";

const EditEvent = ({ item, user }) => {
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isoDate, setIsoDate] = useState("");
  const [time, setTime] = useState("");
  const [useMap, setUseMap] = useState(false);
  const [unlimited, setUnlimited] = useState(item.information.unlimited);
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: item.description.name,
    description: item.description.description,
    type: item.information.type,
    totalGuests: item.information.totalGuests,
    date: item.information.date,
    time: item.information.time,
    location: item.location,
  });
  const [minDate, setMinDate] = useState("");

  const [location, setLocation] = useState(null);
  const [userLocation, setUserLocation] = useState({
    loaded: false,
    coordinates: { lat: 0.0, lng: 0.0 },
  });

  const maxGuestNumber = 250;
  const maxNumber = 9999999999999;
  const dateFormat = "MM-dd-yyyy HH:mm";
  let placeholder = "Desde 1 hasta " + maxGuestNumber;

  useEffect(() => {
    setMinDate(format(new Date(), "yyyy-MM-dd"));
    eventTimeFormat();
    eventDateFormat();
    getImageUrl();

    if (!("geolocation" in navigator)) {
      alert(t("geolocationNotAvailable"));
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  const onSuccess = (location) => {
    setUserLocation({
      loaded: true,
      coordinates: {
        lat: parseFloat(location.coords.latitude),
        lng: parseFloat(location.coords.longitude),
      },
    });
  };

  const onError = (error) => {
    setUserLocation({
      loaded: false,
      error,
    });
  };

  const handleFile = (event) => {
    const selected = event.target.files[0];
    setFile(selected);
    setUrl(URL.createObjectURL(selected));
  };

  const eventDateFormat = () => {
    const parts = item.information.date.split(" ");
    const datePart = parts[0].split("-");
    const isoDate = datePart[2] + "-" + datePart[0] + "-" + datePart[1];
    setIsoDate(isoDate);
  };

  const eventTimeFormat = () => {
    const part = item.information.time.split(" ");
    const timeString = part[1];
    setTime(timeString);
  };

  const getImageUrl = async () => {
    const path = "event/" + item.id + "/event_image/event_image.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
    } catch (error) {
      setUrl(null);
    }
  };

  const uploadImage = async () => {
    const path = "event/" + item.id + "/event_image/event_image.jpg";
    // Create path reference
    const reference = ref(storage, path);
    try {
      await uploadBytes(reference, file);
    } catch (error) {
      //TODO: Handle correctly
    }
  };

  const updateLocation = () => {
    setData({
      name: data.name,
      description: data.description,
      type: data.type,
      totalGuests: data.totalGuests,
      date: data.date,
      time: data.time,
      location: {
        name: location.name,
        placeID: location.placeID,
        address: location.address,
        coordinate: {
          lat: location.latitude.toString(),
          lng: location.longitude.toString(),
        },
      },
    });
    setLocation(null);
    setUseMap(false);
  };

  const checkBoxToggle = () => {
    setUnlimited(!unlimited);
    setData({
      name: data.name,
      description: data.description,
      type: data.type,
      totalGuests: unlimited ? 0 : maxNumber,
      date: data.date,
      time: data.time,
      location: data.location,
    });
  };

  const updateEventInfo = async () => {
    setUploading(true);
    const dataRequest = mapData();
    const updateEvent = httpsCallable(functions, "updateEvent");
    try {
      await updateEvent(dataRequest);
      if (file !== null) {
        await uploadImage();
      }
      navigate("/home/myEvents");
    } catch (error) {
      setUploading(false);
      alert(t("generalError"));
    }
  };

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    if (uploading) return;
    // validating date
    const dateString = isoDate.replace("-", "/");
    const selectedDate = new Date(dateString);
    selectedDate.setSeconds(0);
    selectedDate.setMilliseconds(0);

    var currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    if (selectedDate > currentDate) {
      verifyGuestNumber();
    } else {
      // validating time
      const timeValues = time.split(":");
      const selectedTime = new Date();
      selectedTime.setHours(timeValues[0]);
      selectedTime.setMinutes(timeValues[1]);

      currentDate = new Date();
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);

      if (selectedTime > currentDate) {
        verifyGuestNumber();
      } else {
        alert(t("dateAndTimeError"));
      }
    }
  };

  const verifyGuestNumber = () => {
    if (unlimited) {
      if (item.transactionId === null || item.transactionId === "") {
        saveSessionInfo();
      } else {
        updateEventInfo();
      }
    } else {
      if (data.totalGuests <= 0 || data.totalGuests > maxGuestNumber) {
        alert(t("maxGuestsError", { number: maxGuestNumber }));
        return;
      }
      updateEventInfo();
    }
  };

  const saveSessionInfo = () => {
    // Encrypted session
    const sessionString = JSON.stringify(user);
    const encryptedSession = CryptoJS.AES.encrypt(sessionString, process.env.REACT_APP_ENCRYPTION_SECRET_KEY).toString();
    sessionStorage.setItem("data", encryptedSession);
    const cost = 0.0;
    const eventDescription = {
      eventName: data.name,
      description: data.description,
      cost: cost,
    };
    const eventInformation = {
      type: data.type,
      totalGuests: data.totalGuests,
      date: data.date,
      time: data.time,
      unlimited: unlimited,
    };
    const dataString = JSON.stringify({
      id: item.id,
      owner_id: item.ownerId,
      transactionId: item.transactionId,
      location: data.location,
      information: eventInformation,
      description: eventDescription,
    });
    // Encrypted event data
    const encryptedData = CryptoJS.AES.encrypt(dataString, process.env.REACT_APP_ENCRYPTION_SECRET_KEY).toString();
    sessionStorage.setItem("info", encryptedData);
    navigate("/home/payment");
  };

  const mapData = () => {
    const part = isoDate.split("-");
    const dateValue = new Date(
      part[1] + "-" + part[2] + "-" + part[0] + " " + time
    );
    const dateString = format(dateValue, dateFormat);

    const timeValues = time.split(":");
    const newDate = new Date();
    newDate.setHours(timeValues[0]);
    newDate.setMinutes(timeValues[1]);
    const timeString = format(newDate, dateFormat);

    const jsonData = JSON.stringify({
      id: item.id,
      owner_id: item.ownerId,
      location: data.location,
      transactionId: item.transactionId,
      description: {
        eventName: data.name,
        description: data.description,
        cost: item.description.cost,
      },
      information: {
        type: data.type,
        totalGuests: parseInt(data.totalGuests),
        date: dateString,
        time: timeString,
        unlimited: unlimited,
      },
    });
    const dataRequest = {
      eventData: jsonData,
      date: dateString,
      time: timeString,
      latitude: data.location.coordinate.lat,
      longitude: data.location.coordinate.lng,
      customImage: !(file === null && url === null),
    };
    return dataRequest;
  };

  return (
    <div className={styles.content}>
      <h1>{t("editEventTitle")}</h1>
      <div className={styles.container}>
        <div className={styles.half_container}>
          <h1>{t("informationTitle")}</h1>
          <div>
            <label>{t("name")}</label>
            <input
              placeholder={t("eventNamePlaceholder")}
              type="text"
              name="name"
              required
              value={data.name}
              onChange={(event) => {
                setData({
                  name: event.target.value,
                  description: data.description,
                  type: data.type,
                  totalGuests: data.totalGuests,
                  date: data.date,
                  time: data.time,
                  location: data.location,
                });
              }}
            ></input>

            <textarea
              name="description"
              rows="4"
              cols="50"
              value={data.description}
              placeholder={t("eventDescriptionPlaceholder")}
              onChange={(event) => {
                setData({
                  name: data.name,
                  description: event.target.value,
                  type: data.type,
                  totalGuests: data.totalGuests,
                  date: data.date,
                  time: data.time,
                  location: data.location,
                });
              }}
            ></textarea>

            <div>
              <label>{t("eventTypeTitle")}</label>
              <select
                id="type"
                value={data.type}
                onChange={(event) => {
                  setData({
                    name: data.name,
                    description: data.description,
                    type: event.target.value,
                    totalGuests: data.totalGuests,
                    date: data.date,
                    time: data.time,
                    location: data.location,
                  });
                }}
              >
                <optgroup>
                  <option value="public">{t("public")}</option>
                  <option value="private">{t("private")}</option>
                </optgroup>
              </select>
              <p>
                {data.type === "public" ? (
                  <>{t("publicEventDescription")}</>
                ) : (
                  <>{t("privateEventDescription")}</>
                )}
              </p>
            </div>

            {useMap && (
              <div className={styles.shadow_box}>
                <h2>{t("seekerTitle")}</h2>
                <PlacesAutocomplete
                  setPlace={() => {}}
                  setLocation={setLocation}
                  updateAddress={() => {}}
                />
                <div className={styles.map_container}>
                  <APIProvider
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    libraries={["places"]}
                  >
                    <>
                      <Map
                        mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                        zoom={12}
                        center={{
                          lat: userLocation.coordinates.lat,
                          lng: userLocation.coordinates.lng,
                        }}
                      >
                        {location && (
                          <AdvancedMarker
                            position={{
                              lat: location.latitude,
                              lng: location.longitude,
                            }}
                          >
                            <Pin
                              background={process.env.REACT_APP_PIN_BACKGROUND}
                              glyphColor={process.env.REACT_APP_PIN_GLYPH_COLOR}
                              borderColor={process.env.REACT_APP_PIN_BORDER_COLOR}
                            />
                          </AdvancedMarker>
                        )}
                      </Map>
                      <PlaceClick
                        setLocation={setLocation}
                        updateAddress={() => {}}
                      />
                    </>
                  </APIProvider>
                </div>
                {location && (
                  <div>
                    <h2>{location.name}</h2>
                    <p>{location.address}</p>
                  </div>
                )}
                <div>
                  <button
                    class="solid_button"
                    onClick={() => {
                      setUseMap(false);
                      setLocation(null);
                    }}
                  >
                    {t("cancel")}
                  </button>
                  {location && (
                    <button class="border_button" onClick={updateLocation}>
                      {t("changeLocation")}
                    </button>
                  )}
                </div>
              </div>
            )}

            {!useMap && (
              <div className={styles.shadow_box}>
                <h2>{t("modalLocationTitle")}</h2>
                <h3>{data.location.name}</h3>
                <p>{data.location.address}</p>
                <button
                  class="solid_button"
                  onClick={() => {
                    setUseMap(true);
                  }}
                >
                  {t("changeLocation")}
                </button>
              </div>
            )}

            <br></br>
            {item.information.unlimited && <h2>{t("unlimitedGuestsTitle")}</h2>}

            {!item.information.unlimited && (
              <>
                {!unlimited && (
                  <div>
                    <label>{t("guestNumberTitle")}</label>
                    <input
                      placeholder={placeholder}
                      type="number"
                      name="number"
                      required
                      value={data.totalGuests}
                      onChange={(event) => {
                        setData({
                          name: data.name,
                          description: data.description,
                          type: data.type,
                          totalGuests: event.target.value,
                          date: data.date,
                          time: data.time,
                          location: data.location,
                        });
                      }}
                    />
                  </div>
                )}
                <div className={styles.unlimit_content}>
                  <div className={styles.unlimit_header}>
                    <button onClick={checkBoxToggle}>
                      {!unlimited && (
                        <img src="../../img/check_box_icon.png" alt="Imagen" />
                      )}
                      {unlimited && (
                        <img
                          src="../../img/check_box_fill_icon.png"
                          alt="Imagen"
                        />
                      )}
                    </button>
                    <h2>{t("unlimitedGuestsTitle")}</h2>
                  </div>

                  <p>{t("unilimitedEventDescription")}</p>
                </div>
              </>
            )}

            <label>{t("eventDateTitle")}</label>
            <input
              type="date"
              name="date"
              required
              value={isoDate}
              min={minDate}
              onChange={(event) => {
                setIsoDate(event.target.value);
              }}
            />

            <label>{t("eventTimeTitle")}</label>
            <input
              type="time"
              name="time"
              required
              value={time}
              onChange={(event) => {
                setTime(event.target.value);
              }}
            />

            <label>{t("eventImageTitle")}</label>
            <input type="file" onChange={handleFile} />

            <button class="solid_button" onClick={handleSubmit}>
              {t("updateEventTitle")}
            </button>
            {uploading && <Message message={t("updatingEventTitle")} />}
          </div>
        </div>

        <div className={styles.half_container}>
          <h1>{t("image")}</h1>
          {url == null && (
            <img
              className={styles.image_preview}
              src="../../img/background.png"
              alt="Imagen"
            />
          )}
          {url != null && (
            <img className={styles.image_preview} src={url} alt="Imagen" />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
