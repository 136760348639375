import { useEffect } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

const PlaceClick = ({ setLocation, updateAddress }) => {
  const zoom = 14;
  const map = useMap();
  const library = useMapsLibrary("places");

  useEffect(() => {
    if (!map) return;
    map.addListener("click", (event) => {
      getPlaceAddress(event);
    });
  }, [map, library]);

  const getPlaceAddress = (event) => {
    if (event["placeId"] === undefined) return;
    const placeId = event["placeId"];
    const coordinate = event["latLng"];
    const places = new library.PlacesService(map);
    places.getDetails({ placeId: placeId }, (result) => {
      const name = result["name"];
      const fullAddress = result["formatted_address"];
      updateAddress({ name, fullAddress });
      setLocation({
        name: name,
        placeID: placeId,
        address: fullAddress,
        latitude: coordinate.lat(),
        longitude: coordinate.lng(),
      });
      // Updating map center and zoom
      map.setCenter(coordinate);
      map.setZoom(zoom);
    });
  };

  return <></>;
};

export default PlaceClick;
