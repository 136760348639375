import QRCode from "react-qr-code";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import styles from "./InvitationItem.module.css";
import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "./../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import DialogContent from "@mui/material/DialogContent";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";

const InvitationItem = (props) => {
  const [url, setUrl] = useState("");
  const { t, i18n } = useTranslation();
  const [picture, setPicture] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    information: { date: "", time: "" },
    description: { name: "", description: "" },
    location: { name: "", address: "" },
  });
  const [owner, setOwner] = useState(null);
  const send = "send";
  const accepted = "accepted";

  useEffect(() => {
    if (props.item.status === accepted) {
      fetchEventInfo(props.item.eventId);
    } else {
      setLoading(false);
    }
  }, []);

  const openInvitation = async () => {
    await fetchEventInfo(props.item.eventId);
    setOpen(true);
  };

  const dateString = () => {
    //This helps to parse the date in Safari
    const dateString = data.information.date.replace("-", "/");
    const newDate = new Date(dateString);
    const value = format(newDate, "dd MMM yyyy");
    return value;
  };

  const timeString = () => {
    const dateString = data.information.time.replace("-", "/");
    const newDate = new Date(dateString);
    const value = format(newDate, "HH:mm a");
    return value;
  };

  const getImageUrl = async (eventId) => {
    const path = "event/" + eventId + "/event_image/event_image.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
    } catch (error) {
      setUrl(null);
    }
  };

  const getPictureUrl = async (ownerId) => {
    const path = "user/" + ownerId + "/profile_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setPicture(url);
    } catch (error) {
      setPicture(null);
    }
  };

  const fetchEventInfo = async (eventId) => {
    const requestData = { id: eventId };
    const downloadInfo = httpsCallable(functions, "searchEventById");
    try {
      const result = await downloadInfo(requestData);
      const jsonString = JSON.stringify(result.data.data);
      const json = JSON.parse(jsonString);
      const eventData = {
        id: json.id,
        ownerId: json.owner,
        transactionId: json.transactionId,
        status: json.status,
        location: {
          name: json.place_name,
          placeID: json.place_id,
          address: json.address,
          coordinate: { lat: json.latitude, lng: json.longitude },
        },
        information: {
          type: json.type,
          date: json.date,
          time: json.time,
          totalInvites: json.total_invites,
        },
        description: {
          name: json.name,
          description: json.description,
          cost: json.cost,
        },
      };
      setData(eventData);
      await getImageUrl(eventData.id);
      await fetchOwnerInfo();
      await getPictureUrl(props.item.ownerId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchOwnerInfo = async () => {
    const requestData = { id: props.item.ownerId };
    const downloadInfo = httpsCallable(functions, "downloadProfile");
    try {
      const result = await downloadInfo(requestData);
      const jsonString = JSON.stringify(result.data);
      const json = JSON.parse(jsonString);
      setOwner({
        id: json.id,
        name: json.profile.name,
        lastName: json.profile.last_name,
      });
    } catch (error) {
      // TODO: Handle correctly
    }
  };

  if (loading) {
    return (
      <div className={styles.loader}>
        <h1>{t("searching")}</h1>
        <p>{t("loading")}</p>
        <img src="../../img/lupa.png" />
      </div>
    );
  } else {
    return (
      <div className={styles.content}>
        {props.item.status === send && (
          <div className={styles.send_appearance}>
            <div className={styles.send_content}>
              <h2>{t("receivedInvitation")}</h2>
              <button onClick={() => openInvitation()} />
            </div>
          </div>
        )}

        {props.item.status === accepted && (
          <div className={styles.accepted_content}>
            {url === null && <img src="../../img/background.png" />}
            {url !== null && <img src={url} />}
            <div className={styles.accepted_info}>
              <h2>{data.description.name}</h2>
              <h3>
                {t("date")} : {dateString()}
              </h3>
              <h3>
                {t("time")} : {timeString()}
              </h3>
              <button class="border_button" onClick={() => openInvitation()}>
                {t("seeMore")}
              </button>
            </div>
          </div>
        )}

        {open && (
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <DialogContent>
              <div className={styles.modal}>
                {/* background */}
                {url === null && <img src="../../img/background.png" />}
                {url !== null && <img src={url} alt="Imagen" />}

                <div className={styles.modal_content}>
                  <div className={styles.modal_code}>
                    {props.item.status === accepted && (
                      <QRCode value={props.item.id} />
                    )}
                  </div>

                  {/* event info */}
                  <div className={styles.modal_info}>
                    <h1>{data.description.name}</h1>
                    <h2>
                      {t("date")} :{dateString()}
                    </h2>
                    <h2>
                      {t("time")} : {timeString()}
                    </h2>
                    <p>{data.description.description}</p>
                  </div>

                  {/* event location */}
                  <div className={styles.modal_location}>
                    <h2>{t("modalLocationTitle")}</h2>
                    <div className={styles.modal_map}>
                      <APIProvider
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                        libraries={["places"]}
                      >
                        <Map
                          mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                          zoom={14}
                          center={{
                            lat: parseFloat(data.location.coordinate.lat),
                            lng: parseFloat(data.location.coordinate.lng),
                          }}
                        >
                          <AdvancedMarker
                            position={{
                              lat: parseFloat(data.location.coordinate.lat),
                              lng: parseFloat(data.location.coordinate.lng),
                            }}
                          >
                            {/* add color values on config file */}
                            <Pin
                              background={process.env.REACT_APP_PIN_BACKGROUND}
                              glyphColor={process.env.REACT_APP_PIN_GLYPH_COLOR}
                              borderColor={process.env.REACT_APP_PIN_BORDER_COLOR}
                            />
                          </AdvancedMarker>
                        </Map>
                      </APIProvider>
                    </div>
                    <h2>{data.location.name}</h2>
                    <p>{data.location.address}</p>
                  </div>

                  {/* creator info */}
                  <div className={styles.modal_profile}>
                    <h3>{t("eventCreator")}</h3>
                    {picture == null && (
                      <img src="../../img/account_icon.png" />
                    )}
                    {picture != null && <img src={picture} />}
                    <h2>
                      {owner.name} {owner.lastName}
                    </h2>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  }
};

export default InvitationItem;
