import CryptoJS from 'crypto-js';
import { v4 as uuid } from "uuid";
import Message from "./../../../common/Message";
import styles from "./EventDescription.module.css";
import React, { useEffect, useState } from "react";
import { functions, storage } from "./../../../../firebase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { httpsCallable } from "firebase/functions";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

const EventDescription = ({ user, eventData }) => {
  const eventId = uuid();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const navigationPath = "/home/myEvents";
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [picture, setPicture] = useState(null);
  const [url, setUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    getPictureUrl();
  }, []);

  const handleFile = (event) => {
    const selected = event.target.files[0];
    setFile(selected);
    setUrl(URL.createObjectURL(selected));
  };

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    if (!uploading) {
      if (eventData.information.unlimited) {
        saveSessionInfo();
      } else {
        createEvent();
      }
    }
  };

  const saveSessionInfo = () => {
    // Encrypted session
    const sessionString = JSON.stringify(user);
    const encryptedSession = CryptoJS.AES.encrypt(sessionString, process.env.REACT_APP_ENCRYPTION_SECRET_KEY).toString();
    sessionStorage.setItem("data", encryptedSession);
    const cost = 0.0;
    const eventDescription = {
      eventName: name,
      description: description,
      cost: cost,
    };
    const dataString = JSON.stringify({
      id: "",
      owner_id: user.id,
      transactionId: "",
      location: eventData.location,
      information: eventData.information,
      description: eventDescription,
    });
    // Encrypted event data
    const encryptedData = CryptoJS.AES.encrypt(dataString, process.env.REACT_APP_ENCRYPTION_SECRET_KEY).toString();
    sessionStorage.setItem("info", encryptedData);
    navigate("/home/payment");
  };

  const createEvent = async () => {
    setUploading(true);
    const request = mapData();
    const createEventRequest = httpsCallable(functions, "createEvent");
    try {
      const result = await createEventRequest(request);
      if (result.data.success) {
        if (file != null) {
          uploadImage();
        } else {
          navigate(navigationPath);
        }
      } else {
        alert(t("generalError"));
      }
    } catch (error) {
      setUploading(false);
      alert(t("generalError"));
    }
  };

  const uploadImage = async () => {
    const path = "event/" + eventId + "/event_image/event_image.jpg";
    // Create path reference
    const reference = ref(storage, path);
    try {
      await uploadBytes(reference, file);
      navigate(navigationPath);
    } catch (error) {
      setUploading(false);
      alert(t("generalError"));
    }
  };

  const getPictureUrl = async () => {
    const path = "user/" + user.id + "/profile_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setPicture(url);
    } catch (error) {
      setPicture(null);
    }
  };

  const mapData = () => {
    const cost = 0.0;
    const eventDescription = {
      eventName: name,
      description: description,
      cost: cost,
    };
    const jsonData = JSON.stringify({
      id: eventId,
      owner_id: user.id,
      // Transaction Id is set after pay is done
      transactionId: "",
      location: eventData.location,
      information: eventData.information,
      description: eventDescription,
    });
    const dataRequest = {
      eventData: jsonData,
      date: eventData.information.date,
      time: eventData.information.time,
      latitude: eventData.location.latitude.toString(),
      longitude: eventData.location.longitude.toString(),
      customImage: file != null,
    };
    return dataRequest;
  };

  return (
    <div className={styles.content}>
      <h1>{t("eventDescriptionPlaceholder")}</h1>
      <div className={styles.container}>
        <div className={styles.half_container}>
          <form onSubmit={handleSubmit}>
            <label>{t("eventNamePlaceholder")}</label>
            <input
              type="text"
              name="name"
              required
              onChange={(event) => {
                setName(event.target.value);
              }}
            ></input>

            <textarea
              name="description"
              rows="10"
              placeholder={t("eventDescriptionPlaceholder")}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            ></textarea>

            <p>{t("eventImageDescription")}</p>

            <input type="file" onChange={handleFile}></input>
            <button class="solid_button">{t("createEvent")}</button>
          </form>
          {uploading && <Message message={t("creatingEvent")} />}
        </div>
        <div className={styles.half_container}>
          <div className={styles.image_container}>
            {url === null && (
              <img src="../../img/background.png" alt="Imagen" />
            )}
            {url !== null && <img src={url} alt="Imagen" class="imagen" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDescription;
